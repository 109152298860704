import {
  ASSIGN_TOURS_FAILURE,
  ASSIGN_TOURS_REQUEST,
  ASSIGN_TOURS_SUCCESS,
  RESET_TOURS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  isPosting: false,
  dataPosted: false,
  error: false
};

export const toursReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TOURS_DATA:
      return initialState;

    case ASSIGN_TOURS_REQUEST:
      return {
        ...state,
        isPosting: true,
        dataPosted: false,
        error: false
      };
    case ASSIGN_TOURS_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true,
        data: action.payload
      };
    case ASSIGN_TOURS_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default toursReducer;
