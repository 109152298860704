import { window } from "global";
import qs from "qs";

import config from "../config";

export const printPage = page => {
  document.getElementById("print-frame") &&
    document.getElementById("print-frame").remove();
  const currentDocument = window.document.children[0].innerHTML;
  const iframe = document.createElement("iframe");
  iframe.setAttribute("id", "print-frame");
  iframe.style = `
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
      height: 0;
      z-index: -1;
    `;
  document.body.appendChild(iframe);
  const iframeDocument = iframe.contentWindow.document;
  iframeDocument.open();
  iframeDocument.write(currentDocument);
  setTimeout(() => {
    iframeDocument.close();
    const p = iframeDocument.querySelector(".horizontal-scroll-wrapper");
    for (let i = 0; i < p.childNodes.length; i++) {
      if (i != page) p.removeChild(p.childNodes[i < page ? 0 : 1]);
    }
    iframeDocument.querySelector("#account-header").style.display = "block";
    iframeDocument.querySelector("#header").style.display = "none";
    iframeDocument.querySelector(".wrapper-box").style.width = "auto";
    iframeDocument.querySelector(".wrapper-box").setAttribute("class", "");
    const target = document.getElementById("print-frame");
    try {
      target.contentWindow.document.execCommand("print", false, null);
    } catch {
      target.contentWindow.print();
    }
  }, 1000);
};

export const elementIsOrContains = (element, testElement) => {
  return element === testElement || element.contains(testElement);
};

export const createConstants = (...constants) => {
  return constants.reduce((acc, constant) => {
    acc[constant] = constant;
    return acc;
  }, {});
};

export const createReducer = (initialState, reducerMap) => {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];

    return reducer ? reducer(state, action.payload) : state;
  };
};

export const getBasePath = location => {
  let path = "";
  if (location && location.pathname) {
    path = location.pathname;
  } else {
    if (window) {
      path = window.location.pathname;
    }
  }
  if (path.startsWith("/dashboard")) return "/dashboard";
  if (path.startsWith("/account-dashboard")) return "/account-dashboard";
  if (path.startsWith("/support-dashboard")) return "/support-dashboard";
  if (path.startsWith("/admin-dashboard")) return "/admin-dashboard";
  if (path.startsWith("/theme-manager-dashboard"))
    return "/theme-manager-dashboard";
  if (path.startsWith("/admin-support-dashboard"))
    return "/admin-support-dashboard";
  if (path.startsWith("/product-editor-dashboard"))
    return "/product-editor-dashboard";
  return "/";
};

export const getRoutePath = location => {
  const basePath = getBasePath(location);
  let path = "";
  if (location && location.pathname) {
    path = location.pathname;
  } else {
    if (window) {
      path = window.location.pathname;
    }
  }
  const finalPath = path.split(`${basePath}/`);
  return finalPath[1];
};

export const fetchPromise = async (
  url,
  method = "get",
  headers = {},
  body = null
) => {
  const options = {
    method: method,
    headers: headers,
    credentials: "same-origin"
  };
  if ((method === "post" || method === "put") && body != null)
    options.body = JSON.stringify(body);

  const data = await fetch(url, options);
  const response = await parseResponse(data);
  return response;
};

export const parseResponse = async response => {
  if (response.status === 204) {
    return {};
  }
  if (response.status >= 200 && response.status < 300) {
    const body = await response.json();
    return body;
  } else if (response.status == 401) {
    if (window) {
      window.location.replace("/login");
    }
  } else {
    const body = await response.text();
    return body;
  }
};

//only search by phone number and email
export const searchUser = async (text, getAllUsers = false) => {
  const headers = {};
  if (window) {
    headers["X-User-Agent"] = window.navigator.userAgent;
  }
  const url =
    config.api_url + `/admin/search-user/${text}?getAllUsers=${getAllUsers}`;

  const data = await fetch(url, {
    method: "get",
    headers,
    credentials: "same-origin"
  });
  const response = await parseResponse(data);
  return { user: response };
};

export const findUser = (searchParams = {}) => {
  let url = config.api_url + `/admin/users`;
  const query = qs.stringify(searchParams);
  if (query) {
    url = url + "?" + query;
  }
  return fetchPromise(url, "get");
};

export const getUsers = async (roles, name) => {
  const query = stringify({ roles, name });
  const url = `${config.api_url}/admin/users?${query}`;

  const response = await fetchPromise(url, "get");
  const options = response.users.map(user => {
    return { id: user._id, label: user.name, value: user.name };
  });
  return { options };
};

export const uploadToGoogleCloud = async file => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const data = await fetch(config.api_url + "/admin/upload-to-google-cloud", {
    method: "POST",
    body: formData
  });

  const response = await parseResponse(data);
  return response;
};

export const getOptimisedImage = (
  url = "",
  size = "normal",
  optimiseQuality = true,
  optimiseFormat = true
) => {
  let finalParam;
  let sizeParam;
  switch (size.toLowerCase()) {
    case "small":
      sizeParam = "c_fit,h_100,w_100";
      break;
    case "normal":
      sizeParam = "c_fit,h_200,w_200";
      break;
    case "card":
      sizeParam = "c_fit,h_256,w_256";
      break;
    case "large":
      sizeParam = "c_fit,h_500,w_500";
      break;
    default:
      sizeParam = "";
  }
  finalParam = sizeParam;
  if (optimiseQuality) {
    finalParam = finalParam + ",q_auto";
  }

  if (optimiseFormat) {
    finalParam = finalParam + ",f_auto";
  }
  if (
    finalParam != "" &&
    url.indexOf("res.cloudinary.com/prokure/image/upload/") > 0
  ) {
    const urlParams = url.split("res.cloudinary.com/prokure/image/upload/");
    return (
      "https://res.cloudinary.com/prokure/image/upload/" +
      finalParam +
      "/" +
      urlParams[1]
    );
  } else {
    return url;
  }
};

export const fetchPincodeData = async pincode => {
  const response = await fetchPromise(
    config.api_url + "/admin/resolve-address/" + pincode,
    "get"
  );
  return response;
};

export const fetchGeocoordinate = (address, pincode) => {
  return fetchPromise(
    config.api_url + "/android/geocode",
    "post",
    {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    {
      address,
      pincode
    }
  );
};

export const fetchSuggestion = input => {
  return fetchPromise(
    config.api_url + "/android/address-suggestions/" + encodeURI(input),
    "get"
  );
};

export const fetchWarehouses = () => {
  return fetchPromise(config.api_url + "/seller/warehouse-list", "get");
};

export const fetchPlaceDetails = input => {
  return fetchPromise(
    config.api_url + "/android/place-details/" + encodeURI(input),
    "get"
  );
};

export const getProducts = async search => {
  const response = await fetchPromise(
    config.api_url + "/admin/search/" + search,
    "get"
  );

  const data = response.products.map(product => {
    return { label: product.name, value: product.name };
  });

  return {
    options: data
  };
};

export const resolveCoordinate = address => {
  // eslint-disable-next-line no-undef
  const geocoder = new google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      // eslint-disable-next-line no-undef
      if (status == google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        resolve({ lat, lng });
      } else {
        reject(status);
      }
    });
  });
};

export const getSellerProducts = async search => {
  const response = await fetchPromise(
    config.api_url + "/seller/search/" + search,
    "get"
  );

  const data = response.products.map(product => {
    return { label: product.Product.name, value: product.Product.name };
  });
  return {
    options: data
  };
};

export const traverseKeyword = async (keywordId, parent) => {
  let url = keywordId;
  if (parent) {
    url = url + "?parent=" + parent;
  }
  const data = await fetchPromise(
    config.api_url + "/admin/keyword/traverse/" + url,
    "get"
  );
  let params = {};
  let subCatFilter;
  let subCatData;
  data.keywords.forEach(d => {
    switch (d.groupName) {
      case "category":
        params.Category = d.name.toLowerCase();
        break;
      case "subCategory":
        params.SubCategory = d.name.toLowerCase();
        break;
      case "subCategoryFilter":
        subCatFilter = d.name;
        break;
      default:
        subCatData = d.name;
    }
  });
  if (subCatFilter && subCatData) {
    params[subCatFilter] = subCatData;
  }

  return params;
};

export const reviewKeywords = async products => {
  const data = await fetchPromise(
    config.api_url + "/admin/keyword/bulk/review",
    "post",
    {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    products
  );
  return data;
};

export const urlParams = queryParams => {
  return "?" + qs.stringify(queryParams, { skipNulls: true });
};

// Flatten the nested object,
// from https://stackoverflow.com/a/43081129/12205842
export const flatObject = input => {
  const flat = (res, key, val, pre = "") => {
    const prefix = [pre, key].filter(v => v).join(".");
    return typeof val === "object"
      ? Object.keys(val ?? {}).reduce(
          (prev, curr) => flat(prev, curr, val[curr], prefix),
          res
        )
      : Object.assign(res, { [prefix]: val });
  };

  return Object.keys(input).reduce(
    (prev, curr) => flat(prev, curr, input[curr]),
    {}
  );
};

export const stringify = params => {
  const filterFunction = (prefix, value) => {
    if (value === "" || value === null || value === undefined) {
      return;
    } else {
      return value;
    }
  };
  return qs.stringify(params, {
    filter: filterFunction,
    encode: true,
    encodeValuesOnly: true,
    encoder: str => encodeURIComponent(str),
    arrayFormat: "repeat"
  });
};

const getSentryEnv = () => {
  if (window) {
    return window.__SENTRY_ENV__;
  }
  if (process !== undefined) {
    return process.env.SENTRY_ENV;
  }
};

export const delhiCoordinates = {
  lat: 28.7041,
  lng: 77.1025
};

const environment = getSentryEnv();

export function selectOnEnvironment({
  mela,
  namaste,
  meladev,
  namastedev,
  development
}) {
  if (arguments.length > selectOnEnvironment.length) {
    throw new Error("Too many arguments passed to selectOnEnvironment");
  }
  if (mela === undefined || namaste === undefined) {
    throw new Error(
      "Mela and Namaste defaults are required in arguments to selectOnEnvironment"
    );
  }
  if (
    (meladev === undefined && namastedev !== undefined) ||
    (meladev !== undefined && namastedev === undefined)
  ) {
    throw new Error(
      "selectOnEnvironment cannot be passed dev arguments for only one type"
    );
  }

  switch (environment) {
    case "production":
      return mela;
    case "namaste-production":
      return namaste;
    case "development":
      return meladev === undefined ? mela : meladev;
    case "namaste-development":
      return namastedev === undefined ? namaste : namastedev;
    default:
      if (development === "error") {
        throw new Error(
          "Running this function in local development is not allowed"
        );
      } else {
        return development ?? null;
      }
  }
}

export const IS_DEV =
  environment === "namaste-development" || environment === "development";
export const IS_PROD =
  environment === "namaste-development" || environment === "development";

export const isAdmin = user => {
  return user.roles?.includes("admin");
};

export const isAdminSupport = user => {
  return user.roles?.includes("adminSupport");
};

export const isValidObjectId = id => {
  return id.match(/^[0-9a-fA-F]{24}$/);
};

export const readablePrice = (value, currency) => {
  const [firstChar, secondChar] = value.split("-");
  if (firstChar === "*") return "Under " + currency + parseInt(secondChar);
  if (secondChar === "*") return "Over " + currency + parseInt(firstChar);
  return (
    currency + parseInt(firstChar) + " - " + currency + parseInt(secondChar)
  );
};

export const convertNumberToBoolean = value => {
  return value == "1" ? "Yes" : "No";
};

export const parseIS01806Duration = duration => {
  // Basic validation
  if (!duration || typeof duration !== "string") {
    return {
      isValid: false,
      error: "Invalid duration string provided"
    };
  }

  // Regular expression to match simple duration patterns
  const regex = /^P(?:T)?(\d+|.\d+|\d+.\d+)([YMWDHMS])$/;
  const match = duration.trim().match(regex);

  if (!match) {
    return {
      isValid: false,
      error:
        "Invalid duration format. Expected format: P<number><unit> or PT<number><unit>"
    };
  }

  // eslint-disable-next-line no-unused-vars
  const [_, value, unit] = match;

  // Convert value to number
  const numericValue = parseFloat(value);

  // Map of units to their full names
  const unitMap = {
    Y: "Year",
    M: "months",
    W: "weeks",
    D: "days",
    H: "hours",
    S: "seconds"
  };

  // Handle special case for minutes vs months
  const isTime = duration.includes("T");

  if (unit === "M") {
    unitMap.M = isTime ? "minutes" : "months";
  }

  return {
    isValid: true,
    value: numericValue,
    unit: unit,
    unitName: unitMap[unit],
    original: duration,
    isTime: isTime
  };
};
