import { GET_ACTIVE_CALLS } from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  calls: []
};

export const callsReducer = createReducer(initialState, {
  [GET_ACTIVE_CALLS]: (state, payload) => {
    return {
      ...state,
      calls: payload.calls
    };
  }
});

export default callsReducer;
