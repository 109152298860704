import {
  FETCH_SHIPMENT_NDR_STATUS_FAILURE,
  FETCH_SHIPMENT_NDR_STATUS_REQUEST,
  FETCH_SHIPMENT_NDR_STATUS_SUCCESS,
  UPDATE_SHIPMENT_NDR_FAILURE,
  UPDATE_SHIPMENT_NDR_REQUEST,
  UPDATE_SHIPMENT_NDR_SUCCESS
} from "../../constants/actionTypes";

const initialState = {
  dataFetched: false,
  isFetching: false,
  error: false,

  isPosting: false,
  dataPosted: false
};

export const ndrReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHIPMENT_NDR_STATUS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_SHIPMENT_NDR_STATUS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true
      };
    case FETCH_SHIPMENT_NDR_STATUS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case UPDATE_SHIPMENT_NDR_REQUEST:
      return {
        ...state,
        isPosting: true,
        error: false
      };
    case UPDATE_SHIPMENT_NDR_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_SHIPMENT_NDR_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default ndrReducer;
