import {
  CREATE_OR_UPDATE_PRICE_CLUSTERS_REQUEST,
  FETCH_PRICE_CLUSTERS_REQUEST,
  FETCH_PRICE_CLUSTERS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  data: [],
  isFetching: true,
  isCreatingOrUpdating: false
};

export const priceClustersReducer = createReducer(initialState, {
  [FETCH_PRICE_CLUSTERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_OR_UPDATE_PRICE_CLUSTERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [FETCH_PRICE_CLUSTERS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreatingOrUpdating: false
    };
  }
});

export default priceClustersReducer;
