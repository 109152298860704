import {
  COUPON_FILTER_CHANGE,
  DELETE_COUPONS_REQUEST,
  DELETE_COUPONS_SUCCESS,
  FETCH_COUPON_FILTERDATA_SUCCESS,
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  coupons: [],
  filterList: [
    "paymentModes",
    "paymentProviders",
    "platforms",
    "customerGroups",
    "roles",
    // "categories",
    // "subCategories",
    // "brands",
    "daysOfWeek"
  ],
  filterData: {},
  filters: {
    couponStatuses: [],
    discountTypes: [],
    paymentModes: [],
    paymentProviders: [],
    platforms: [],
    customerGroups: [],
    roles: [],
    categories: [],
    subCategories: [],
    brands: [],
    daysOfWeek: []
  },
  isDeleting: false
};

export const couponsReducer = createReducer(initialState, {
  [FETCH_COUPONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_COUPONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_COUPON_FILTERDATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [COUPON_FILTER_CHANGE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [DELETE_COUPONS_REQUEST]: state => {
    return {
      ...state,
      isDeleting: true
    };
  },
  [DELETE_COUPONS_SUCCESS]: state => {
    return {
      ...state,
      isDeleting: false
    };
  }
});

export default couponsReducer;
