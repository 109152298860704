import "whatwg-fetch";
import "../styles/core.scss";

import loadable from "@loadable/component";
import React from "react";
import { Route, Switch, withRouter } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import NotFoundPage from "../components/NotFoundPage";
import HorizontalRouterRootMain from "../containers/HorizontalRouterRootMain";
import { userIsGuest, userIsNotAuthenticated } from "../utils/authWrappers";
import { getRouteComponentMapping } from "./AllRoutes";

const DeveloperFirst = loadable(
  () => import("../views/common-views/DeveloperFirst")
);
const Login = loadable(() => import("../views/common-views/Login"));
const FrontPage = loadable(() => import("../views/common-views/FrontPage"));
const FeatureList = loadable(() => import("../views/common-views/FeatureList"));
const ForgotPassword = loadable(
  () => import("../views/common-views/ForgotPassword")
);
const Logout = loadable(() => import("../views/common-views/Logout"));
const MobileFirst = loadable(() => import("../views/common-views/MobileFirst"));
const ResetPassword = loadable(
  () => import("../views/common-views/ResetPassword")
);
const VerifyOTP = loadable(() => import("../views/common-views/VerifyOTP"));
const DemoPage = loadable(() => import("../views/common-views/DemoPage"));

const App = withRouter(() => {
  const user = useSelector(state => state.auth.user);
  const session = useSelector(state => state.auth.session);
  const myRoles = [];
  if (user) {
    if (user.roles.indexOf("admin") > -1) {
      myRoles.push("admin");
    }

    if ((user.roles && user.roles.indexOf("seller") > -1) || user.account) {
      myRoles.push("seller");
    }

    if (
      (user.roles &&
        user.roles.indexOf("account.admin") > -1 &&
        user.roles.indexOf("account.owner") > -1) ||
      user.account
    ) {
      myRoles.push("account");
    }

    if (
      (user.roles && user.roles.indexOf("customerCare") > -1) ||
      user.account
    ) {
      myRoles.push("support");
    }

    if (
      (user.roles && user.roles.indexOf("themeManager") > -1) ||
      user.account
    ) {
      myRoles.push("themeManager");
    }

    if (user.roles && user.roles.indexOf("adminSupport") > -1) {
      myRoles.push("adminSupport");
    }

    if (user.roles && user.roles.indexOf("productEditor") > -1) {
      myRoles.push("productEditor");
    }
  }

  let routeIndex = 0;
  const allRoutesInSwitch = [];
  if (session && session.roleBasedRoute) {
    session.roleBasedRoute.forEach(route =>
      route.roles.forEach(role => {
        let path;
        switch (role) {
          case "seller":
            path = "/dashboard" + route.path;
            break;
          case "themeManager":
            path = "/theme-manager-dashboard" + route.path;
            break;
          case "adminSupport":
            path = "/admin-support-dashboard" + route.path;
            break;
          case "productEditor":
            path = "/product-editor-dashboard" + route.path;
            break;
          default:
            path = `/${role}-dashboard` + route.path;
        }
        routeIndex++;
        if (myRoles.includes(role)) {
          allRoutesInSwitch.push(
            <Route
              exact={!!route.exact}
              key={routeIndex}
              path={path}
              component={getRouteComponentMapping(route.path, role)}
            />
          );
        }
      })
    );
  }

  return (
    <div className={session?.options?.theme ?? "theme-purple"}>
      <Switch>
        <Route exact path="/" component={userIsGuest(FrontPage)} />
        <Route path="/login" component={userIsNotAuthenticated(Login)} />
        <Route path="/logout" component={Logout} />
        <Route path="/signup" component={Login} />
        <Route
          path="/forgot-password"
          component={userIsNotAuthenticated(ForgotPassword)}
        />
        <Route
          path="/verify-otp"
          component={userIsNotAuthenticated(VerifyOTP)}
        />
        <Route
          path="/reset-password"
          component={userIsNotAuthenticated(ResetPassword)}
        />
        <Route path="/demo" component={DemoPage} />
        <Route path="/developer-first" component={DeveloperFirst} />
        <Route path="/mobile-first" component={MobileFirst} />
        <Route path="/feature-list" component={FeatureList} />

        {user && (
          <Route
            render={props => (
              <HorizontalRouterRootMain
                {...props}
                roleRoutes={allRoutesInSwitch}
              />
            )}
          />
        )}

        <Route path="*" component={NotFoundPage} />
      </Switch>
    </div>
  );
});

export default App;
