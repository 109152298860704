import {
  FETCH_WALLET_DATA_REQUEST,
  FETCH_WALLET_DATA_SUCCESS,
  RESET_WALLET_DATA
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialWalletState = {
  page: 1,
  count: 0,
  isFetchingWallet: false,
  wallet: {}
};

export const walletReducer = createReducer(initialWalletState, {
  [FETCH_WALLET_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingWallet: true
    };
  },
  [FETCH_WALLET_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingWallet: false
    };
  },
  [RESET_WALLET_DATA]: () => {
    return {
      ...initialWalletState
    };
  }
});

export default walletReducer;
