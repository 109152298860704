import {
  FETCH_SCANCODE_REQUEST,
  FETCH_SCANCODE_SUCCESS,
  RESET_SCANCODE,
  UPDATE_SCANCODE_REQUEST,
  UPDATE_SCANCODE_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  isUpdating: false,
  scancode: {}
};

export const scancodeReducer = createReducer(initialState, {
  [FETCH_SCANCODE_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_SCANCODE_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [UPDATE_SCANCODE_REQUEST]: state => {
    return {
      ...state,
      isUpdating: true
    };
  },
  [UPDATE_SCANCODE_SUCCESS]: state => {
    return {
      ...state,
      isUpdating: false
    };
  },
  [RESET_SCANCODE]: () => {
    return initialState;
  }
});

export default scancodeReducer;
