import {
  FETCH_ONDC_SELLER_VERIFICATION_FAILURE,
  FETCH_ONDC_SELLER_VERIFICATION_REQUEST,
  FETCH_ONDC_SELLER_VERIFICATION_SUCCESS
} from "../constants/actionTypes";

export const initialState = {
  verifications: {},
  error: null
};

export const initialVerificationState = {
  verifications: [],
  isFetching: false,
  error: null,
  count: 0,
  page: 1
};

const verifyOndcSeller = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONDC_SELLER_VERIFICATION_REQUEST:
      return {
        ...state,
        verifications: {
          ...state.verifications,
          [action.payload.page]: {
            ...(state.verifications[action.payload.page] ||
              initialVerificationState),
            isFetching: true,
            error: null
          }
        }
      };

    case FETCH_ONDC_SELLER_VERIFICATION_SUCCESS:
      return {
        ...state,
        verifications: {
          ...state.verifications,
          [action.payload.page]: {
            isFetching: false,
            error: null,
            verifications: action.payload.response.verifications,
            count: action.payload.response.count,
            page: action.payload.response.page
          }
        }
      };

    case FETCH_ONDC_SELLER_VERIFICATION_FAILURE:
      return {
        ...state,
        verifications: {
          ...state.verifications,
          [action.payload.page]: {
            ...(state.verifications[action.payload.page] ||
              initialVerificationState),
            isFetching: false,
            error: action.payload.error
          }
        }
      };

    default:
      return state;
  }
};

export default verifyOndcSeller;
