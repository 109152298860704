import {
  SELLER_FETCH_CANCELLED_ORDERS,
  SELLER_FETCH_CANCELLED_ORDERS_SUCCESS
} from "../../constants/sellers";
import { createReducer } from "../../utils";

const initialState = {
  isFetching: true,
  count: 0,
  orders: [],
  cancellations: [],
  nav: "ongoing",
  page: 1
};

export const sellerOrdersReducer = createReducer(initialState, {
  [SELLER_FETCH_CANCELLED_ORDERS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [SELLER_FETCH_CANCELLED_ORDERS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  }
});

export default sellerOrdersReducer;
