import { REFRESH_PAGE } from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  url: []
};

export const refreshReducer = createReducer(initialState, {
  [REFRESH_PAGE]: (state, payload) => {
    return {
      ...state,
      url: payload
    };
  }
});

export default refreshReducer;
