import {
  CREATE_PRODUCT_TAG_FAILURE,
  CREATE_PRODUCT_TAG_REQUEST,
  CREATE_PRODUCT_TAG_SUCCESS,
  FETCH_PRODUCT_TAGS,
  FETCH_PRODUCT_TAGS_SUCCESS,
  UPDATE_PRODUCT_TAG_FAILURE,
  UPDATE_PRODUCT_TAG_REQUEST,
  UPDATE_PRODUCT_TAG_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  productTags: [],
  isFetching: true,
  productTag: {},
  error: false,
  isAccountFetching: true,
  isCreatingOrUpdating: false
};

export const productTagReducer = createReducer(initialState, {
  [FETCH_PRODUCT_TAGS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      error: false
    };
  },
  [FETCH_PRODUCT_TAGS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [CREATE_PRODUCT_TAG_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [CREATE_PRODUCT_TAG_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_PRODUCT_TAG_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [UPDATE_PRODUCT_TAG_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [UPDATE_PRODUCT_TAG_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_PRODUCT_TAG_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  }
});

export default productTagReducer;
