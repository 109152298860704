import {
  ADD_PRODUCT_TO_CART_DATA,
  ADD_PRODUCT_TO_CART_REQUEST,
  FETCH_PRODUCT_CATEGORY_DATA_SUCCESS,
  FETCH_PRODUCT_DATA_REQUEST,
  FETCH_PRODUCT_DATA_SUCCESS,
  FETCH_SUBCATEGORY_FILTERS_REQUEST,
  FETCH_SUBCATEGORY_FILTERS_SUCCESS,
  FETCH_UPLOADED_ID_SUCCESS,
  RESET_PRODUCT_DATA,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_VARIATIONS_REQUEST,
  UPDATE_PRODUCT_VARIATIONS_SUCCESS,
  UPDATE_SUBCATEGORY_FILTERS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  product: {},
  sellers: [],
  category: [],
  subCategory: [],
  subCategoryFilters: [],
  productVariations: [],
  isPostingVariations: false,
  isFetching: true,
  isUpdating: false,
  isCartUpdating: false,
  postedUploadId: ""
};

export const productReducer = createReducer(initialState, {
  [FETCH_PRODUCT_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_PRODUCT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_PRODUCT_CATEGORY_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [UPDATE_PRODUCT_REQUEST]: state => {
    return {
      ...state,
      isUpdating: true
    };
  },
  [UPDATE_PRODUCT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isUpdating: false,
      ...payload
    };
  },
  [FETCH_SUBCATEGORY_FILTERS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_SUBCATEGORY_FILTERS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [UPDATE_SUBCATEGORY_FILTERS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_UPLOADED_ID_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [ADD_PRODUCT_TO_CART_REQUEST]: state => {
    return {
      ...state,
      isCartUpdating: true
    };
  },
  [ADD_PRODUCT_TO_CART_DATA]: state => {
    return {
      ...state,
      isCartUpdating: false
    };
  },
  [RESET_PRODUCT_DATA]: () => {
    return { ...initialState };
  },
  [UPDATE_PRODUCT_VARIATIONS_REQUEST]: state => {
    return {
      ...state,
      isPostingVariations: true
    };
  },
  [UPDATE_PRODUCT_VARIATIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isPostingVariations: false,
      ...payload
    };
  }
});

export default productReducer;
