import {
  FETCH_IGM_ISSUE_REQUEST,
  FETCH_IGM_ISSUE_SUCCESS,
  FETCH_IGM_REPORT_REQUEST,
  FETCH_IGM_REPORT_SUCCESS,
  FETCH_IGM_REQUEST,
  FETCH_IGM_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialIGMState = {
  page: 1,
  count: 0,
  isFetching: true,
  data: [],
  issue: null,
  isFetchingIssue: false,
  isFetchingReport: false,
  report: null
};

export const imagesReducer = createReducer(initialIGMState, {
  [FETCH_IGM_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_IGM_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_IGM_ISSUE_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingIssue: true
    };
  },
  [FETCH_IGM_ISSUE_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingIssue: false
    };
  },
  [FETCH_IGM_REPORT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReport: true
    };
  },
  [FETCH_IGM_REPORT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReport: false
    };
  }
});

export default imagesReducer;
