import loadable from "@loadable/component";

const AccountSettings = loadable(
  () => import("../views/account-views/AccountSettings")
);
const Campaigns = loadable(() => import("../views/account-views/Campaigns"));
const Contacts = loadable(() => import("../views/account-views/Contacts"));
const CreateStaff = loadable(
  () => import("../views/account-views/CreateStaff")
);
const CreateUser = loadable(() => import("../views/account-views/CreateUser"));
const CustomerGroup = loadable(
  () => import("../views/account-views/CustomerGroup")
);
const LocationCluster = loadable(
  () => import("../views/account-views/LocationCluster")
);
const MarginCluster = loadable(
  () => import("../views/account-views/MarginCluster")
);
const Neighbourhoods = loadable(
  () => import("../views/account-views/Neighbourhoods")
);
const AccountOrder = loadable(() => import("../views/account-views/Order"));
const AccountPayment = loadable(() => import("../views/account-views/Payment"));
const AccountPayments = loadable(
  () => import("../views/account-views/Payments")
);
const ProductTag = loadable(() => import("../views/account-views/ProductTag"));
const Release = loadable(() => import("../views/account-views/Release"));
const AccountTranslations = loadable(
  () => import("../views/account-views/Translations")
);
const User = loadable(() => import("../views/account-views/User"));
const AccountUsers = loadable(() => import("../views/account-views/Users"));
const AccountUserTrack = loadable(
  () => import("../views/account-views/UserTrack")
);
const AccountReports = loadable(
  () => import("../views/admin-views/AccountReports")
);
const Account = loadable(() => import("../views/admin-views/accounts/Account"));
const Accounts = loadable(
  () => import("../views/admin-views/accounts/Accounts")
);
const CreateAccount = loadable(
  () => import("../views/admin-views/accounts/CreateAccount")
);
const Aggregations = loadable(
  () => import("../views/admin-views/Aggregations")
);
const AndroidApps = loadable(() => import("../views/admin-views/AndroidApps"));
const AppVersion = loadable(() => import("../views/admin-views/AppVersion"));
const AccountCarts = loadable(() => import("../views/admin-views/Carts"));
const CashbackRule = loadable(
  () => import("../views/admin-views/CashbackRule")
);
const Component = loadable(() => import("../views/admin-views/Component"));
const ComponentRevisionHistory = loadable(
  () => import("../views/admin-views/ComponentRevisionHistory")
);
const Components = loadable(() => import("../views/admin-views/Components"));
const CreateCampaign = loadable(
  () => import("../views/admin-views/CreateCampaign")
);
const CreateLayoutRule = loadable(
  () => import("../views/admin-views/CreateLayoutRule")
);
const CreateProductRule = loadable(
  () => import("../views/admin-views/CreateProductRule")
);
const Keywords = loadable(() => import("../views/admin-views/Keywords"));
const VerifyKyc = loadable(() => import("../views/admin-views/KYC"));
const Layout = loadable(() => import("../views/admin-views/Layout"));
const LayoutRevisionHistory = loadable(
  () => import("../views/admin-views/LayoutRevisionHistory")
);
const LayoutRule = loadable(() => import("../views/admin-views/LayoutRule"));
const LayoutRules = loadable(() => import("../views/admin-views/LayoutRules"));
const Layouts = loadable(() => import("../views/admin-views/Layouts"));
const Ledger = loadable(() => import("../views/admin-views/Ledger"));
const OpeningBalance = loadable(
  () => import("../views/admin-views/OpeningBalance")
);
const OrderReports = loadable(
  () => import("../views/admin-views/OrderReports")
);
const CreatePlayAccount = loadable(
  () => import("../views/admin-views/play-accounts/CreatePlayAccount")
);
const PlayAccount = loadable(
  () => import("../views/admin-views/play-accounts/PlayAccount")
);
const PlayAccounts = loadable(
  () => import("../views/admin-views/play-accounts/PlayAccounts")
);
const PlayAppReviewQueue = loadable(
  () => import("../views/admin-views/PlayAppReviewQueue")
);
const Product = loadable(() => import("../views/admin-views/Product"));
const ProductRules = loadable(
  () => import("../views/admin-views/ProductRules")
);
const Products = loadable(() => import("../views/admin-views/Products"));
const ProductUpload = loadable(
  () => import("../views/admin-views/ProductUpload")
);
const Referral = loadable(() => import("../views/admin-views/Referral"));
const Referrals = loadable(() => import("../views/admin-views/Referrals"));
const SpotInstances = loadable(
  () => import("../views/admin-views/SpotInstances")
);
const SubscriptionPlans = loadable(
  () => import("../views/admin-views/SubscriptionPlans")
);
const Theme = loadable(() => import("../views/admin-views/Theme"));
const UploadProducts = loadable(
  () => import("../views/admin-views/UploadProducts")
);
const Uploads = loadable(() => import("../views/admin-views/Uploads"));
const Wallet = loadable(() => import("../views/admin-views/Wallet"));
const Wishlists = loadable(() => import("../views/admin-views/Wishlists"));
const Dashboard = loadable(
  () => import("../views/common-admin-views/Dashboard")
);
const AllPickups = loadable(() => import("../views/delivery-views/AllPickups"));
const AccountShipments = loadable(
  () => import("../views/delivery-views/AllShipments")
);
const AssignPickups = loadable(
  () => import("../views/delivery-views/AssignPickups")
);
const DeliveryVendor = loadable(
  () => import("../views/delivery-views/DeliveryVendor")
);
const DeliveryVendors = loadable(
  () => import("../views/delivery-views/DeliveryVendors")
);
const Invoices = loadable(() => import("../views/delivery-views/Invoices"));
const Pickup = loadable(() => import("../views/delivery-views/Pickup"));
const ProcessPickup = loadable(
  () => import("../views/delivery-views/ProcessPickup")
);
const PurchaseOrders = loadable(
  () => import("../views/delivery-views/PurchaseOrders")
);
const BuyerFulfilment = loadable(
  () => import("../views/order-views/BuyerFulfilment")
);
const BuyerFulfilments = loadable(
  () => import("../views/order-views/BuyerFulfilments")
);
const AccountCancellations = loadable(
  () => import("../views/order-views/Cancellations")
);
const Exchange = loadable(() => import("../views/order-views/Exchange"));
const Exchanges = loadable(() => import("../views/order-views/Exchanges"));
const Inventories = loadable(() => import("../views/order-views/Inventories"));
const InventoryAdditionRequest = loadable(
  () => import("../views/order-views/InventoryAdditionRequest")
);
const InventoryAdditionRequests = loadable(
  () => import("../views/order-views/InventoryAdditionRequests")
);
const AccountOrders = loadable(() => import("../views/order-views/Orders"));
const OndcOrders = loadable(() => import("../views/order-views/OndcOrders"));
const SellerAccount = loadable(() => import("../views/seller-views/Account"));
const Coupons = loadable(() => import("../views/seller-views/Coupons"));
const CreateCoupon = loadable(
  () => import("../views/seller-views/CreateCoupon")
);
const CreateInventoryRule = loadable(
  () => import("../views/seller-views/CreateInventoryRule")
);
const CreateShipments = loadable(
  () => import("../views/seller-views/CreateShipments")
);
const CreateWarehouse = loadable(
  () => import("../views/seller-views/CreateWarehouse")
);
const SellerFulfilments = loadable(
  () => import("../views/seller-views/Fulfilments")
);
const Images = loadable(() => import("../views/seller-views/Images"));
const InventoryRules = loadable(
  () => import("../views/seller-views/InventoryRules")
);
const SellerOrder = loadable(() => import("../views/seller-views/OrderNew"));
const SellerOrders = loadable(() => import("../views/seller-views/Orders"));
const SellerProducts = loadable(
  () => import("../views/seller-views/SellerProducts")
);
const SellerSettings = loadable(
  () => import("../views/seller-views/SellerSettings")
);
const Warehouse = loadable(() => import("../views/seller-views/Warehouse"));
const Warehouses = loadable(() => import("../views/seller-views/Warehouses"));
const AllotShipmentBatch = loadable(
  () => import("../views/shipment-views/AllotShipmentBatch")
);
const Batch = loadable(() => import("../views/shipment-views/Batch"));
const BluedartInvoice = loadable(
  () => import("../views/shipment-views/BluedartInvoice")
);
const ConfigureBatch = loadable(
  () => import("../views/shipment-views/ConfigureBatch")
);
const DelhiveryInvoice = loadable(
  () => import("../views/shipment-views/DelhiveryInvoice")
);
const EditBatchLocations = loadable(
  () => import("../views/shipment-views/EditBatchLocations")
);
const EditTours = loadable(() => import("../views/shipment-views/EditTours"));
const OngoingBatch = loadable(
  () => import("../views/shipment-views/OngoingBatch")
);
const Plans = loadable(() => import("../views/shipment-views/Plans"));
const PlanShipmentRoute = loadable(
  () => import("../views/shipment-views/PlanShipmentRoute")
);
const Shipment = loadable(() => import("../views/shipment-views/Shipment"));
const ShipmentBatches = loadable(
  () => import("../views/shipment-views/ShipmentBatches")
);
const Shipments = loadable(() => import("../views/shipment-views/Shipments"));
const ThirdPartyShipments = loadable(
  () => import("../views/shipment-views/ThirdPartyShipments")
);
const UpdateAddress = loadable(
  () => import("../views/shipment-views/UpdateAddress")
);
const UploadData = loadable(() => import("../views/shipment-views/UploadData"));
const Chat = loadable(() => import("../views/user-views/Chat"));
const ChatMessageView = loadable(
  () => import("../views/user-views/ChatMessageView")
);

const Scancode = loadable(() => import("../views/admin-views/Scancode"));
const Scancodes = loadable(() => import("../views/admin-views/Scancodes"));

const ONDCIssues = loadable(() => import("../views/admin-views/ondc/Issues"));
const ONDCIssue = loadable(() => import("../views/admin-views/ondc/Issue"));

const OndcSellerVerification = loadable(
  () => import("../views/admin-views/ondc/VerifySeller")
);

export const routeComponentMapping = {
  "": Dashboard,
  "/chat": Chat,
  "/chatView/:id": ChatMessageView,
  "/wishlists": Wishlists,

  "/user/:id": User,
  "/user/:id/:nav": User,
  "/users-track": AccountUserTrack,
  "/users": AccountUsers,
  "/staff/create": CreateStaff,
  "/buyer/create": CreateUser,

  "/carts": AccountCarts,
  "/orders": AccountOrders,
  "/ondc-orders": OndcOrders,
  "/order/:id": AccountOrder,

  "/cancellations": AccountCancellations,

  "/uploads": Uploads,
  "/uploaded-products/:uploadId": UploadProducts,
  "/products": Products,
  "/products/:text": Products,
  "/product/:id": Product,
  "/upload": ProductUpload,
  "/margin-cluster": MarginCluster,
  "/customer-group": CustomerGroup,

  "/inventory-rules": InventoryRules,
  "/inventories": Inventories,
  "/inventory-rule/:id": CreateInventoryRule,

  "/layouts": Layouts,
  "/layout/:id": Layout,
  "/layout-rule/:id": CreateLayoutRule,
  "/layout-rule": LayoutRule,
  "/layout-rules": LayoutRules,
  "/layout-revision-history/*": LayoutRevisionHistory,
  "/components": Components,
  "/component/:id": Component,
  "/component-revision-history/*": ComponentRevisionHistory,

  "/warehouses": Warehouses,
  "/warehouse/:id": Warehouse,
  "/warehouse-create": CreateWarehouse,
  "/location-cluster": LocationCluster,
  "/purchase-orders": SellerFulfilments,
  "/pickups/purchase-orders": PurchaseOrders,
  "/assign-pickups": AssignPickups,
  "/pickups": AllPickups,
  "/pickup/:id": Pickup,
  "/process-pickup-request/:id": ProcessPickup,
  "/keyword/:groupName": Keywords,
  "/aggregations": Aggregations,
  "/accounts": Accounts,
  "/account/create": CreateAccount,
  "/account/edit/:id": CreateAccount,
  "/account/:id": Account,
  "/account": SellerAccount,
  "/inventory-request/:id": InventoryAdditionRequest,

  "/create-campaign": CreateCampaign,
  "/address/:id": UpdateAddress,
  "/address/new": UpdateAddress,

  "/allot-batches": AllotShipmentBatch,
  "/batches": ShipmentBatches,
  "/batch/edit-locations/:batchId": EditBatchLocations,
  "/batch/plans/:batchId": Plans,
  "/batch/configure/:batchId": ConfigureBatch,
  "/batch/edit-tours/:batchId/:planId": EditTours,
  "/batch/:id": Batch,
  "/ongoing-batches/:batchId/:planId": OngoingBatch,
  "/ongoing-batches/:batchId": OngoingBatch,
  "/plan-shipment-route/:id": PlanShipmentRoute,
  "/third-party-shipments": ThirdPartyShipments,
  "/create-shipments": CreateShipments,
  "/shipments": Shipments,
  "/account-shipments": AccountShipments,
  "/shipment/:id": Shipment,
  "/delivery-vendors": DeliveryVendors,
  "/delivery-vendor/:id": DeliveryVendor,
  "/delhivery-invoice": DelhiveryInvoice,
  "/bluedart-invoice": BluedartInvoice,
  "/shipment-invoices": Invoices,

  "/uploadData": UploadData,
  "/exchanges": Exchanges,
  "/exchange/:id": Exchange,

  "/settings": SellerSettings,

  "/payments": AccountPayments,
  "/payment/:id": AccountPayment,

  "/scancodes": Scancodes,
  "/scancode/:id": Scancode,

  "/referrals": Referrals,
  "/referral/:id": Referral,

  "/opening-balance": OpeningBalance,
  "/ledger/:userId": Ledger,
  "/wallet": Wallet,
  "/wallet/:userId": Wallet,
  "/cashback-rules": CashbackRule,
  "/campaigns": Campaigns,
  "/reports-mela-hidden": OrderReports,
  "/product-rules": ProductRules,
  "/product-rule/:id": CreateProductRule,
  "/android-apps/:accountId": AndroidApps,
  "/android-apps": AndroidApps,
  "/app-version": AppVersion,
  "/release": Release,
  "/theme": Theme,
  "/product-tag": ProductTag,
  "/product-details": BuyerFulfilments,
  "/product-detail/:id": BuyerFulfilment,
  "/translations": AccountTranslations,
  "/neighbourhoods": Neighbourhoods,
  "/contacts": Contacts,
  "/play-accounts": PlayAccounts,
  "/play-account/:id": PlayAccount,
  "/play-account/create": CreatePlayAccount,
  "/play-account/edit/:id": CreatePlayAccount,
  "/coupons": Coupons,
  "/coupon/create": CreateCoupon,
  "/spot-instances": SpotInstances,
  "/subscription-plans": SubscriptionPlans,
  "/account-reports": AccountReports,
  "/playapp-review": PlayAppReviewQueue,
  "/verify-kyc": VerifyKyc,
  "/images": Images,
  "/ondc-issues": ONDCIssues,
  "/ondc-issue/:id": ONDCIssue,
  "/ondc-seller-verification": OndcSellerVerification
};

export const getRouteComponentMapping = (path, role) => {
  let component = routeComponentMapping[path];
  switch (path) {
    case "/settings":
      switch (role) {
        case "seller":
          component = SellerSettings;
          break;
        case "account":
        case "admin":
          component = AccountSettings;
          break;
      }
      break;

    case "/purchase-orders":
      switch (role) {
        case "seller":
          component = SellerFulfilments;
          break;
        case "account":
          component = InventoryAdditionRequests;
          break;
      }
      break;

    case "/shipments":
      switch (role) {
        case "seller":
          component = Shipments;
          break;
        case "account":
        case "support":
          component = AccountShipments;
      }
      break;

    case "/products":
      switch (role) {
        case "seller":
          component = SellerProducts;
          break;
        case "support":
        case "admin":
        case "account":
          component = Products;
          break;
      }
      break;

    case "/orders":
      switch (role) {
        case "seller":
          component = SellerOrders;
          break;
        case "account":
        case "support":
          component = AccountOrders;
          break;
      }
      break;

    case "/ondc-orders":
      switch (role) {
        case "account":
        case "support":
          component = OndcOrders;
          break;
      }
      break;

    case "/order/:id":
      switch (role) {
        case "seller":
          component = SellerOrder;
          break;
        case "account":
        case "support":
          component = AccountOrder;
          break;
      }
      break;
  }
  return component;
};
