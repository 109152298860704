import { createBrowserHistory } from "history";
import React from "react";
import { MemoryRouter, Router } from "react-horizontal-router-dom";
import { Provider } from "react-redux";

import App from "../src/routes/index.js";
import { configureAppStore, createStore } from "./store";

const history = createBrowserHistory();

// Create Redux store with state injected by the server

const store = window.Electron
  ? createStore(window.__SESSION_DATA__, null)
  : configureAppStore(window.__PRELOADED_STATE__);

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const RootContainer = () => {
  return (
    <Provider store={store}>
      {window.Electron ? (
        <MemoryRouter
          initialEntries={["/", { pathname: "/" }]}
          initialIndex={1}>
          <App />
        </MemoryRouter>
      ) : (
        <Router history={history}>
          <App />
        </Router>
      )}
    </Provider>
  );
};
export default RootContainer;
