import _find from "lodash/find";

import {
  CHECKBOX_FOR_BFS_SHIPMENT,
  FETCH_BFS_DATA_REQUEST,
  FETCH_BFS_DATA_SUCCESS,
  FETCH_BFS_FOR_SHIPMENT_DATA_REQUEST,
  FETCH_BFS_FOR_SHIPMENT_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  buyerfulfilments: [],
  buyerFulfilmentsShipment: [],
  adminAddress: {},
  page: 1,
  count: 0
};

export const buyerFulfilmentsReducer = createReducer(initialState, {
  [FETCH_BFS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [FETCH_BFS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_BFS_FOR_SHIPMENT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_BFS_FOR_SHIPMENT_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [CHECKBOX_FOR_BFS_SHIPMENT]: (state, payload) => {
    const buyerFulfilmentsShipment = state.buyerFulfilmentsShipment;
    let isAllChecked = state.isAllChecked;
    switch (payload.op) {
      case "add":
        _find(buyerFulfilmentsShipment, { meta: payload.data.meta }).isChecked =
          true;
        break;
      case "remove":
        _find(buyerFulfilmentsShipment, { meta: payload.data.meta }).isChecked =
          false;
        break;
      case "all":
        buyerFulfilmentsShipment.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        buyerFulfilmentsShipment.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      buyerFulfilmentsShipment: [...buyerFulfilmentsShipment],
      isAllChecked
    };
  }
});

export default buyerFulfilmentsReducer;
