import {
  CREATE_OR_UPDATE_LOCATION_CLUSTERS_REQUEST,
  FETCH_LOCATION_CLUSTERS_DATA_SUCCESS,
  FETCH_LOCATION_CLUSTERS_REQUEST,
  FETCH_LOCATIONS_DATA_SUCCESS,
  FETCH_LOCATIONS_REQUEST
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  keyValues: {},
  data: [],
  isFetching: true,
  isCreatingOrUpdating: false
};

export const locationClusterReducer = createReducer(initialState, {
  [FETCH_LOCATIONS_REQUEST]: (state, payload) => {
    const { key } = payload;
    return {
      ...state,
      keyValues: {
        ...state.keyValues,
        [key]: {
          isFetching: true,
          data: []
        }
      }
    };
  },
  [FETCH_LOCATIONS_DATA_SUCCESS]: (state, payload) => {
    const { key, value } = payload;
    return {
      ...state,
      keyValues: {
        ...state.keyValues,
        [key]: {
          isFetching: false,
          data: value
        }
      }
    };
  },
  [FETCH_LOCATION_CLUSTERS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_OR_UPDATE_LOCATION_CLUSTERS_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [FETCH_LOCATION_CLUSTERS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreatingOrUpdating: false
    };
  }
});

export default locationClusterReducer;
