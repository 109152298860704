import {
  FETCH_BUYER_LAYOUT_REQUEST,
  FETCH_BUYER_LAYOUT_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  layouts: {},
  isFetching: false
};

export const buyerLayoutReducer = createReducer(initialState, {
  [FETCH_BUYER_LAYOUT_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_BUYER_LAYOUT_SUCCESS]: (state, payload) => {
    const { path, ...layoutData } = payload;
    return {
      ...state,
      layouts: {
        ...state.layouts,
        [path]: layoutData
      },
      isFetching: false
    };
  }
});

export default buyerLayoutReducer;
