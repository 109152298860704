import moment from "moment";

import {
  ADD_CHAT_MESSAGE,
  ADD_CHAT_USER,
  CHANGE_ACTIVE_CHAT,
  FETCH_CHATS_REQUEST,
  FETCH_CHATS_SUCCESS,
  GET_CHAT_USERS,
  GET_GROUP_DATA,
  LAST_ACTIVE_TIMESTAMP,
  REMOVE_CHAT_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  UPDATE_CHAT_PRODUCTS,
  UPDATE_CHAT_USER
} from "../constants/actionTypes";
import { createReducer } from "../utils/index";

const initialState = {
  activeChat: null,
  isGroup: false,
  page: 1,
  lastTimestamp: new Date().getTime(),
  chatUsers: [],
  chatUser: {},
  chatData: [],
  groupData: {},
  isFetching: false,
  showMore: true,
  lastSeen: false,
  position: "top"
};

const humaniseChatData = (chatData = [], newData = [], position) => {
  moment.locale("en-chat");

  if (newData.length == 0) {
    return chatData;
  }

  newData = addTimeStamp(newData);
  newData = addTail(newData);
  newData = addContinuity(newData);

  if (chatData.length == 0) {
    return newData;
  }

  if (position == "top") {
    newData = combineChat(newData, chatData);
  } else if (position == "bottom") {
    newData = combineChat(chatData, newData);
  }
  return newData;
};

const combineChat = (newData, chatData) => {
  moment.locale("en-chat");
  let chatDataInitialKey = -1;
  for (let j = 0; j < chatData.length; j++) {
    if (chatData[j].type != "system-message") {
      chatDataInitialKey = j;
      break;
    }
  }
  let firstChat = {};
  if (chatDataInitialKey > -1) {
    firstChat = chatData[chatDataInitialKey];
    chatData.splice(0, chatDataInitialKey + 1);
  }

  const lastChat = newData[newData.length - 1];
  let timestamp = false;
  if (
    moment(lastChat.timestamp).calendar() !=
    moment(firstChat.timestamp).calendar()
  ) {
    timestamp = true;
  }

  if (timestamp) {
    firstChat.tail = true;
  }

  if (!timestamp && firstChat.authorKey == lastChat.authorKey) {
    newData[newData.length - 1].continuity = true;
    firstChat.tail = false;
  }

  if (timestamp) {
    newData.push({
      type: "system-message",
      date: moment(firstChat.timestamp).calendar(),
      tail: false,
      continuity: false
    });
  }
  newData.push(firstChat);
  newData = newData.concat(chatData);
  return newData;
};

const addTimeStamp = (data = []) => {
  moment.locale("en-chat");
  const finalData = [];
  if (data.length >= 0) {
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i];
      if (i == 0) {
        finalData.push({
          type: "system-message",
          date: moment(currentData.timestamp).calendar()
        });
      } else {
        const prevData = data[i - 1];
        if (
          moment(currentData.timestamp).calendar() !=
          moment(prevData.timestamp).calendar()
        ) {
          finalData.push({
            type: "system-message",
            date: moment(currentData.timestamp).calendar()
          });
        }
      }
      if (currentData.announcement) {
        currentData.type = "system-message";
      } else {
        currentData.type = "message-chat";
      }
      finalData.push(currentData);
    }
  }
  return finalData;
};

const addTail = (data = []) => {
  const finalData = [];
  if (data.length >= 0) {
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i];
      currentData.tail = false;
      if (i == 0) {
        if (currentData.type == "message-chat") {
          currentData.tail = true;
        }
      } else {
        const prevData = data[i - 1];
        if (prevData.type != "message-chat") {
          currentData.tail = true;
        }
        if (
          prevData.type == "message-chat" &&
          currentData.type == "message-chat" &&
          prevData.authorKey != currentData.authorKey
        ) {
          currentData.tail = true;
        }
      }
      finalData.push(currentData);
    }
  }
  return finalData;
};

const addContinuity = (data = []) => {
  const finalData = [];
  if (data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      const currentData = data[i];
      data.continuity = false;
      if (i < data.length - 1) {
        const nextData = data[i + 1];
        if (
          currentData.type == "message-chat" &&
          nextData.type == "message-chat" &&
          currentData.authorKey == nextData.authorKey
        ) {
          currentData.continuity = true;
        }
      }
      finalData.push(currentData);
    }
  }
  return finalData;
};

export const chatDataReducer = createReducer(initialState, {
  [GET_CHAT_USERS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [ADD_CHAT_USER]: (state, payload) => {
    const chatUsers = state.chatUsers
      .filter(item => {
        return item.chatId !== payload.data.chatId;
      })
      .unshift(payload.data);
    return {
      ...state,
      chatUsers: chatUsers
    };
  },
  [UPDATE_CHAT_USER]: (state, payload) => {
    let chatUsers = state.chatUsers;
    const key = chatUsers.findIndex(item => {
      return item.chatId == payload.data.chatId;
    });
    if (
      chatUsers[key].lastMessage.timestamp == payload.data.lastMessage.timestamp
    ) {
      if (key > -1) {
        chatUsers[key] = payload.data;
      }
    } else {
      if (key > -1) {
        chatUsers = chatUsers.delete(key);
      }
      chatUsers = chatUsers.unshift(payload.data);
    }

    state.chatUsers = chatUsers;
    return state;
  },
  [CHANGE_ACTIVE_CHAT]: (state, payload) => {
    const isGroup = payload.chatId && !payload.chatId.startsWith("1on1");
    return {
      ...state,
      activeChat: payload.chatId,
      isGroup: isGroup,
      chatData: [],
      showMore: true,
      lastSeen: false
    };
  },
  [LAST_ACTIVE_TIMESTAMP]: (state, payload) => {
    return {
      ...state,
      lastTimestamp: payload.lastTimestamp
    };
  },
  [FETCH_CHATS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_CHATS_SUCCESS]: (state, payload) => {
    payload.chatData = humaniseChatData(
      state.chatData,
      payload.chatData,
      "top"
    );

    return {
      ...state,
      ...payload,
      isFetching: false,
      position: "top"
    };
  },
  [ADD_CHAT_MESSAGE]: (state, payload) => {
    const chatData = state.chatData;
    const key = state.chatData.findIndex(
      chat => chat.timestamp === payload.timestamp
    );
    if (key > -1) {
      chatData[key] = payload;
      return {
        ...state,
        chatData: chatData
      };
    } else {
      return {
        ...state,
        chatData: humaniseChatData(chatData, [payload], "bottom"),
        position: "bottom"
      };
    }
  },
  [UPDATE_CHAT_MESSAGE]: (state, payload) => {
    const chatData = state.chatData;
    const key = chatData.findIndex(
      chat => chat.timestamp === payload.timestamp
    );
    if (key > -1) {
      chatData[key] = payload;
      return {
        ...state,
        chatData: chatData
      };
    }
    return state;
  },
  [UPDATE_CHAT_PRODUCTS]: (state, payload) => {
    const chatData = state.chatData;
    const key = chatData.findIndex(
      chat => chat.timestamp == payload.chat.timestamp
    );
    if (key > -1) {
      chatData[key] = payload.chat;
      return {
        ...state,
        chatData: chatData
      };
    }
    return state;
  },
  [REMOVE_CHAT_MESSAGE]: (state, payload) => {
    let chatData = state.chatData;
    const key = chatData.findIndex(
      chat => chat.timestamp === payload.timestamp
    );
    if (key > -1) {
      chatData = chatData.delete(key);
      return {
        ...state,
        chatData: chatData
      };
    }
    return state;
  },
  [GET_GROUP_DATA]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
});

export default chatDataReducer;
