import {
  ADD_PICKUPS_FOR_INVOICE,
  ADD_SHIPMENTS_FOR_INVOICE,
  FETCH_PICKUP_INVOICES_DATA_SUCCESS,
  FETCH_SHIPMENT_INVOICES_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  pickupIds: [],
  invoices: [],
  shipmentIds: []
};

export const invoicesReducer = createReducer(initialState, {
  [ADD_PICKUPS_FOR_INVOICE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [FETCH_PICKUP_INVOICES_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [ADD_SHIPMENTS_FOR_INVOICE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [FETCH_SHIPMENT_INVOICES_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
});

export default invoicesReducer;
