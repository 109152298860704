import _find from "lodash/find";

import {
  ASSIGN_FOR_PICKUP_CHECKED,
  ASSIGN_FOR_SHIPMENT_CHECKED,
  FETCH_EXCHANGES_DATA_REQUEST,
  FETCH_EXCHANGES_DATA_SUCCESS,
  FETCH_EXCHANGES_FOR_PICKUPS_REQUEST,
  FETCH_EXCHANGES_FOR_PICKUPS_SUCCESS,
  FETCH_EXCHANGES_FOR_SHIPMENTS_REQUEST,
  FETCH_EXCHANGES_FOR_SHIPMENTS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  exchanges: [],
  pickupExchanges: [],
  shipmentExchanges: [],
  count: 0,
  nav: "new",
  isFetching: false,
  type: "all",
  isAllChecked: false
};

export const exchangesReducer = createReducer(initialState, {
  [FETCH_EXCHANGES_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [FETCH_EXCHANGES_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_EXCHANGES_FOR_PICKUPS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_EXCHANGES_FOR_PICKUPS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [ASSIGN_FOR_PICKUP_CHECKED]: (state, payload) => {
    const pickupExchanges = state.pickupExchanges;
    let isAllChecked = state.isAllChecked;
    switch (payload.operation) {
      case "add":
        _find(pickupExchanges, { meta: payload.data.meta }).isChecked = true;
        break;
      case "remove":
        _find(pickupExchanges, { meta: payload.data.meta }).isChecked = false;
        break;
      case "all":
        pickupExchanges.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        pickupExchanges.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      pickupExchanges: [...pickupExchanges],
      isAllChecked
    };
  },
  [FETCH_EXCHANGES_FOR_SHIPMENTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_EXCHANGES_FOR_SHIPMENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [ASSIGN_FOR_SHIPMENT_CHECKED]: (state, payload) => {
    const shipmentExchanges = state.shipmentExchanges;
    let isAllChecked = state.isAllChecked;
    const exchange = _find(shipmentExchanges, { meta: payload.data.meta });
    switch (payload.operation) {
      case "add":
        exchange.isChecked = true;
        break;
      case "remove":
        exchange.isChecked = false;
        break;
      case "all":
        shipmentExchanges.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        shipmentExchanges.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      shipmentExchanges: [...shipmentExchanges],
      isAllChecked
    };
  }
});

export default exchangesReducer;
