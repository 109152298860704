import {
  DELETE_USER_ADDRESSES_DATA,
  DELETE_USER_ADDRESSES_REQUEST,
  FETCH_CHECKOUT_DATA_REQUEST,
  FETCH_CHECKOUT_DATA_SUCCESS,
  FETCH_USER_ADDRESSES,
  FETCH_USER_ADDRESSES_SUCCESS,
  UPDATE_USER_ADDRESSES_DATA,
  UPDATE_USER_ADDRESSES_REQUEST
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isAddressFetching: true,
  isCheckoutDataFetching: true,
  isAddressUpdating: false,
  isAddressDeleting: false,
  selectedAddress: "",
  addresses: [],
  checkoutData: {}
};

export const checkoutReducer = createReducer(initialState, {
  [FETCH_USER_ADDRESSES]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isAddressFetching: true
    };
  },
  [FETCH_USER_ADDRESSES_SUCCESS]: (state, payload) => {
    let selectedAddress = "";
    if (payload.addresses && payload.addresses.length > 0) {
      selectedAddress = payload.addresses[0].id;
    }
    return {
      ...state,
      ...payload,
      isAddressFetching: false,
      selectedAddress
    };
  },
  [FETCH_CHECKOUT_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCheckoutDataFetching: true
    };
  },
  [FETCH_CHECKOUT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCheckoutDataFetching: false
    };
  },
  [UPDATE_USER_ADDRESSES_REQUEST]: state => {
    return {
      ...state,
      isAddressUpdating: true
    };
  },
  [UPDATE_USER_ADDRESSES_DATA]: (state, payload) => {
    const { oldAddressId, address } = payload;
    const addresses = oldAddressId
      ? state.addresses.map(item => {
          if (item.id === oldAddressId) {
            return address;
          }
          return item;
        })
      : [...state.addresses, address];
    return {
      ...state,
      addresses: [...addresses],
      isAddressUpdating: false,
      selectedAddress: address.id
    };
  },
  [DELETE_USER_ADDRESSES_REQUEST]: state => {
    return {
      ...state,
      isAddressDeleting: true
    };
  },
  [DELETE_USER_ADDRESSES_DATA]: (state, payload) => {
    let selectedAddress = "";
    const addresses = state.addresses.filter(
      address => address.id != payload.addressId
    );
    if (addresses && addresses.length > 0) {
      const address = addresses[0];
      selectedAddress = address.id;
    }
    return {
      ...state,
      addresses,
      isAddressDeleting: false,
      selectedAddress
    };
  }
});

export default checkoutReducer;
