import {
  FETCH_WISHLISTS_DATA_REQUEST,
  FETCH_WISHLISTS_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  page: 1,
  size: 20,
  sortBy: "",
  totalCount: 0,
  wishlists: []
};

export const wishlistsReducer = createReducer(initialState, {
  [FETCH_WISHLISTS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_WISHLISTS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  }
});

export default wishlistsReducer;
