export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SHOW_FLOATING_NOTIFICATION = "SHOW_FLOATING_NOTIFICATION";
export const HIDE_FLOATING_NOTIFICATION = "HIDE_FLOATING_NOTIFICATION";

export const ADD_NOTIFICATION = "ADD_NOTIFICATION_SS"; // Changing this name as it conflicts with the name in reapop's inbuilt action
export const SET_NOTIFICATION_LAST_SEEN = "SET_NOTIFICATION_LAST_SEEN";

export const SELECT_DASHBOARD_TAB = "SELECT_DASHBOARD_TAB";
export const COLLAPSE_VERTICAL_TAB_BAR = "COLLAPSE_VERTICAL_TAB_BAR";

export const FETCH_SELLER_FULFILMENTS = "FETCH_SELLER_FULFILMENTS";
export const FETCH_SELLER_FULFILMENTS_SUCCESS =
  "FETCH_SELLER_FULFILMENTS_SUCCESS";
export const UPDATE_SELLER_FULFILMENTS = "UPDATE_SELLER_FULFILMENTS";

export const SELLER_FETCH_ORDERS_REQUEST = "SELLER_FETCH_ORDERS_REQUEST";
export const SELLER_FETCH_ORDERS_SUCCESS = "SELLER_FETCH_ORDERS_SUCCESS";
export const SET_SEARCH_SPECS = "SET_SEARCH_SPECS";
export const TOGGLE_ORDER_SELECTED = "TOGGLE_ORDER_SELECTED";
export const POST_ORDERS = "POST_ORDERS";
export const SET_SPINNER = "SET_SPINNER";
export const RESET_ORDERS_STATE = "RESET_ORDERS_STATE";

export const FETCH_EXCHANGE_REQUEST = "FETCH_EXCHANGE_REQUEST";
export const FETCH_EXCHANGE_LIMIT = 20;
export const TOGGLE_EXCHANGE_SELECTED = "TOGGLE_EXCHANGE_SELECTED";
export const SET_SEARCH_SPECS_EXCHANGE = "SET_SEARCH_SPECS_EXCHANGE";
export const RESET_EXCHANGE_STATE = "RESET_EXCHANGE_STATE";

export const FETCH_PRODUCTS_FOR_SHIPMENT = "FETCH_PRODUCTS_FOR_SHIPMENT";
export const FETCH_PRODUCTS_FOR_SHIPMENT_SUCCESS =
  "FETCH_PRODUCTS_FOR_SHIPMENT_SUCCESS";

export const SELLER_FETCH_CANCELLED_ORDERS = "SELLER_FETCH_CANCELLED_ORDERS";
export const SELLER_FETCH_CANCELLED_ORDERS_SUCCESS =
  "SELLER_FETCH_CANCELLED_ORDERS_SUCCESS";

export const FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_REQUEST =
  "FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_REQUEST";
export const FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_SUCCESS =
  "FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_SUCCESS";
export const CHECKBOX_FOR_BFS_SELLER_SHIPMENT =
  "CHECKBOX_FOR_BFS_SELLER_SHIPMENT";
