import {
  FETCH_ORDER_REPORTS,
  FETCH_ORDER_REPORTS_FAILURE,
  FETCH_ORDER_REPORTS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  orderReports: []
};

const reportsReducer = createReducer(initialState, {
  [FETCH_ORDER_REPORTS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_ORDER_REPORTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_ORDER_REPORTS_FAILURE]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      error: payload.error
    };
  }
});

export default reportsReducer;
