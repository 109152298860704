import {
  FETCH_BF_DATA_REQUEST,
  FETCH_BF_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  buyerfulfilment: {}
};

export const buyerfulfilmentReducer = createReducer(initialState, {
  [FETCH_BF_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_BF_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  }
});

export default buyerfulfilmentReducer;
