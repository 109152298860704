import {
  FETCH_BATCHES_FAILURE,
  FETCH_BATCHES_REQUEST,
  FETCH_BATCHES_SUCCESS,
  RESET_BATCHES_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: [],
  dataFetched: false,
  isFetching: false,
  error: false
};

export const batchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BATCHES_DATA:
      return initialState;

    case FETCH_BATCHES_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_BATCHES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_BATCHES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default batchesReducer;
