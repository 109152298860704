import React from "react";
import { Link } from "react-horizontal-router-dom";

const SectionHeader = props => {
  const { logo } = props;
  return (
    <div className="section-header">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <Link to="/feature-list">
          <div
            className="btn"
            style={{
              marginLeft: "32px"
            }}>
            <span>Features</span>
          </div>
        </Link>
      </div>
      <div>
        <Link to="/login">
          <div
            className="btn"
            style={{
              marginRight: "32px"
            }}>
            <span>LOGIN</span>
          </div>
        </Link>
        <Link to="/signup">
          <div className="btn">
            <span style={{ color: "#7d7d7e" }}>SIGNUP</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SectionHeader;
