import {
  DELETE_COMPONENT_REQUEST,
  DELETE_COMPONENT_SUCCESS,
  FETCH_COMPONENT_HISTORY_REQUEST,
  FETCH_COMPONENT_HISTORY_SUCCESS,
  FETCH_COMPONENT_REQUEST,
  FETCH_COMPONENT_SUCCESS,
  FETCH_COMPONENTS_REQUEST,
  FETCH_COMPONENTS_SUCCESS,
  MARK_COMPONENT_DEFAULT_REQUEST,
  MARK_COMPONENT_DEFAULT_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  components: [],
  count: 0,
  isFetchingComponents: false,
  component: [],
  actionPage: 0,
  isFetching: false,
  isDeleting: false,
  componentRevisionHistory: [],
  isFetchingRevisionHistory: false
};

export const componentReducer = createReducer(initialState, {
  [FETCH_COMPONENTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingComponents: true
    };
  },
  [FETCH_COMPONENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingComponents: false
    };
  },
  [FETCH_COMPONENT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_COMPONENT_SUCCESS]: (state, payload) => {
    const { page } = payload;
    const component = state.component
      .filter(l => l.page != page)
      .map(l => {
        const defaultComponent = l.component._id == payload.component._id;
        return {
          ...l,
          component: {
            ...l.component,
            default: defaultComponent
          }
        };
      });
    return {
      ...state,
      component: [...component, payload],
      isFetching: false
    };
  },
  [FETCH_COMPONENT_HISTORY_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingRevisionHistory: true
    };
  },
  [FETCH_COMPONENT_HISTORY_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingRevisionHistory: false
    };
  },
  [MARK_COMPONENT_DEFAULT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [MARK_COMPONENT_DEFAULT_SUCCESS]: (state, payload) => {
    const { componentId } = payload;
    const component = state.component.map(l => {
      const defaultComponent = l.component._id == componentId;
      return {
        ...l,
        component: {
          ...l.component,
          default: defaultComponent
        }
      };
    });
    return {
      ...state,
      component: [...component],
      isFetching: false
    };
  },
  [DELETE_COMPONENT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isDeleting: true
    };
  },
  [DELETE_COMPONENT_SUCCESS]: (state, payload) => {
    const { page } = payload;
    const component = state.component.filter(l => l.page != page);
    return {
      ...state,
      component: [...component],
      isFetching: false,
      isDeleting: false
    };
  }
});

export default componentReducer;
