import {
  CREATE_ADDRESS_FAILURE,
  CREATE_ADDRESS_REQUEST,
  CREATE_ADDRESS_SUCCESS,
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
  RESET_ADDRESS_DATA,
  UPDATE_ADDRESS_FAILURE,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS
} from "../../constants/actionTypes";

const initialState = {
  data: {},
  dataFetched: false,
  isFetching: false,
  updating: false,
  creating: false,
  error: false
};

export const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ADDRESS_DATA:
      return initialState;

    case FETCH_ADDRESS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_ADDRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        updating: true,
        error: false
      };
    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        data: action.payload
      };
    case UPDATE_ADDRESS_FAILURE:
      return {
        ...state,
        dataPosted: false,
        error: action.payload
      };
    case CREATE_ADDRESS_REQUEST:
      return {
        ...state,
        creating: true,
        error: false
      };
    case CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        creating: false,
        dataPosted: true,
        error: false,
        data: action.payload
      };
    case CREATE_ADDRESS_FAILURE:
      return {
        ...state,
        dataPosted: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addressReducer;
