import React from "react";

export const MenuDivider = props => {
  const { style, label } = props;
  return (
    <li className="nav-header" style={{ ...style, marginTop: "1.5rem" }}>
      <span style={{ fontSize: "0.70rem" }}>{label}</span>
    </li>
  );
};
