import {
  CREATE_ANDROID_APP_DATA,
  CREATE_ANDROID_APP_REQUEST,
  FETCH_ANDROID_APP_DATA_REQUEST,
  FETCH_ANDROID_APP_DATA_SUCCESS,
  PROMOTE_ANDROID_APP_FAILURE,
  PROMOTE_ANDROID_APP_REQUEST,
  PROMOTE_ANDROID_APP_SUCCESS,
  UPLOAD_ANDROID_APP_FAILURE,
  UPLOAD_ANDROID_APP_REQUEST,
  UPLOAD_ANDROID_APP_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  isCreating: false,
  response: {},
  apps: [],
  count: 0
};

export const androidAppsReducer = createReducer(initialState, {
  [FETCH_ANDROID_APP_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_ANDROID_APP_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [CREATE_ANDROID_APP_REQUEST]: state => {
    return {
      ...state,
      isCreating: true,
      response: {}
    };
  },
  [UPLOAD_ANDROID_APP_REQUEST]: state => {
    return {
      ...state,
      isCreating: true,
      response: {}
    };
  },
  [PROMOTE_ANDROID_APP_REQUEST]: state => {
    return {
      ...state,
      isCreating: true,
      response: {}
    };
  },
  [CREATE_ANDROID_APP_DATA]: (state, payload) => {
    if (payload.success) {
      return {
        ...state,
        isCreating: false,
        response: { success: true },
        apps: payload.data,
        count: payload.count
      };
    } else {
      return {
        ...state,
        isCreating: false,
        response: payload
      };
    }
  },
  [UPLOAD_ANDROID_APP_SUCCESS]: (state, payload) => {
    if (payload.success) {
      return {
        ...state,
        isCreating: false,
        response: { success: true },
        apps: payload.data,
        count: payload.count
      };
    } else {
      return {
        ...state,
        ...payload,
        isCreating: false,
        response: payload
      };
    }
  },
  [UPLOAD_ANDROID_APP_FAILURE]: (state, payload) => {
    if (payload.success) {
      return {
        ...state,
        isCreating: false,
        response: { success: true },
        apps: payload.data,
        count: payload.count
      };
    } else {
      return {
        ...state,
        ...payload,
        isCreating: false,
        response: payload
      };
    }
  },
  [PROMOTE_ANDROID_APP_SUCCESS]: (state, payload) => {
    if (payload.success) {
      return {
        ...state,
        isCreating: false,
        response: { success: true },
        apps: payload.data,
        count: payload.count
      };
    } else {
      return {
        ...state,
        ...payload,
        isCreating: false,
        response: payload
      };
    }
  },
  [PROMOTE_ANDROID_APP_FAILURE]: (state, payload) => {
    if (payload.success) {
      return {
        ...state,
        isCreating: false,
        response: { success: true },
        apps: payload.data,
        count: payload.count
      };
    } else {
      return {
        ...state,
        ...payload,
        isCreating: false,
        response: payload
      };
    }
  }
});

export default androidAppsReducer;
