import React from "react";
import { useSelector } from "react-redux";

import { Menu, menuItemComponent } from "../components/Menu";
import UserMenu from "../components/UserMenu";
import logo from "../images/prokure_logo_white_with_text.png";
import { getBasePath, getRoutePath } from "../utils";
import SelectNav from "./SelectNav";

const NavBar = props => {
  const { openLink, location } = props;
  const { session, user } = useSelector(state => state.auth);
  const { options, navBarDetails, roleBasedNavbar } = session;

  const getRoleFromPath = () => {
    const pathsRoles = {};
    const basePath = getBasePath(location);
    const roles = Object.keys(roleBasedNavbar);
    roles.forEach(r => {
      pathsRoles[roleBasedNavbar[r].route] = r;
    });
    return pathsRoles[basePath];
  };

  const logoFinal = options && options.logo ? options.logo : logo;
  const basePath = getBasePath(location);
  const MenuItem = menuItemComponent(path => {
    openLink(`${basePath}/${path}`, false, false);
  });

  const role = getRoleFromPath();
  const navbar = roleBasedNavbar[role] ? roleBasedNavbar[role].navbar : [];

  // Create children MenuItem components.
  const orderedParents = [];
  const myPages = {};

  navbar.forEach((nav, index) => {
    if (navBarDetails[nav]) {
      const mainPage = navBarDetails[nav].parent;
      const page = navBarDetails[nav];
      const pageMenuItem = (
        <MenuItem
          key={index}
          label={page.title}
          route={page.route}
          iconName={page.iconName}
        />
      );
      if (myPages[mainPage] && myPages[mainPage].length > 0) {
        myPages[mainPage].push(pageMenuItem);
      } else {
        myPages[mainPage] = [pageMenuItem];
      }
    }
  });

  // Populate children in parent pages.
  Object.keys(myPages).map(key => {
    const parentPage = { ...navBarDetails[key] };
    if (parentPage) {
      parentPage.children = myPages[key];
      orderedParents.push(parentPage);
    }
  });

  // Order parent navs by priority.
  orderedParents.sort((a, b) =>
    a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0
  );

  const currentPathname = getRoutePath(location);

  return (
    <div className="app-nav">
      <div
        className="flex column navside bg-theme-gradient-108"
        style={{ color: "white" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "0px"
          }}>
          <img src={logoFinal} style={{ width: "120px", margin: "10px" }} />
        </div>
        <div style={{ flex: 1 }}>
          <SelectNav
            openLink={openLink}
            location={location}
            roles={user.roles}
          />
          <Menu currentPathname={currentPathname} basePath={basePath}>
            {orderedParents.map(page => {
              return page.children.length === 1 ? (
                page.children[0]
              ) : (
                <MenuItem
                  key={page.title}
                  label={page.title}
                  route={page.route}
                  iconName={page.iconName}>
                  {page.children}
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <UserMenu />
      </div>
    </div>
  );
};

export default NavBar;
