import {
  CREATE_CAMPAIGN_REQUEST,
  FETCH_ACCOUNT_CAMPAIGN_DATA_REQUEST,
  FETCH_ACCOUNT_CAMPAIGN_DATA_SUCCESS,
  FETCH_ACCOUNT_CAMPAIGNS_REQUEST,
  FETCH_ACCOUNT_CAMPAIGNS_SUCCESS,
  UPDATE_CAMPAIGN_REQUEST
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  isFetchingCampaign: true,
  isCreating: false,
  isUpdating: false,
  campaigns: [],
  campaignCount: 0,
  details: {},
  campaignData: {}
};

export const campaignReducer = createReducer(initialState, {
  [CREATE_CAMPAIGN_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [UPDATE_CAMPAIGN_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [FETCH_ACCOUNT_CAMPAIGNS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_ACCOUNT_CAMPAIGNS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_ACCOUNT_CAMPAIGN_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingCampaign: true
    };
  },
  [FETCH_ACCOUNT_CAMPAIGN_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingCampaign: false
    };
  }
});

export default campaignReducer;
