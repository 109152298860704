import {
  FETCH_LOCATIONS_BY_BATCH_FAILURE,
  FETCH_LOCATIONS_BY_BATCH_REQUEST,
  FETCH_LOCATIONS_BY_BATCH_SUCCESS,
  RESET_BATCHES_LOCATIONS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    batchData: [],
    batch: {},
    bounds: {}
  },
  dataFetched: false,
  isFetching: false,
  error: false
};

export const batchLocationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BATCHES_LOCATIONS_DATA:
      return initialState;

    case FETCH_LOCATIONS_BY_BATCH_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_LOCATIONS_BY_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_LOCATIONS_BY_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default batchLocationsReducer;
