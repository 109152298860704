import _find from "lodash/find";

import {
  FETCH_PICKUP_DATA_REQUEST,
  FETCH_PICKUP_DATA_SUCCESS,
  PICKUP_PRODUCTS_TOGGLE_CHECKED
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  pickup: {},
  isAllChecked: false
};

export const pickupReducer = createReducer(initialState, {
  [FETCH_PICKUP_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [FETCH_PICKUP_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [PICKUP_PRODUCTS_TOGGLE_CHECKED]: (state, payload) => {
    const pickup = state.pickup;
    const PickupProducts = pickup.PickupProducts;
    let isAllChecked = state.isAllChecked;
    switch (payload.op) {
      case "add":
        _find(PickupProducts, {
          id: payload.id,
          status: "pending"
        }).isChecked = true;
        break;
      case "remove":
        _find(PickupProducts, {
          id: payload.id,
          status: "pending"
        }).isChecked = false;
        break;
      case "all":
        PickupProducts.forEach(value => {
          if (value.status === "pending") value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        PickupProducts.forEach(value => {
          if (value.status === "pending") value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    pickup.PickupProducts = PickupProducts;
    return {
      ...state,
      pickup: { ...pickup },
      isAllChecked
    };
  }
});

export default pickupReducer;
