import {
  CREATE_WAREHOUSE_FAILURE,
  CREATE_WAREHOUSE_REQUEST,
  CREATE_WAREHOUSE_SUCCESS,
  CREATE_WAREHOUSE_VENDOR_FAILURE,
  CREATE_WAREHOUSE_VENDOR_REQUEST,
  CREATE_WAREHOUSE_VENDOR_SUCCESS,
  DELETE_WAREHOUSE_FAILURE,
  DELETE_WAREHOUSE_REQUEST,
  DELETE_WAREHOUSE_SUCCESS,
  EDIT_WAREHOUSE_FAILURE,
  EDIT_WAREHOUSE_REQUEST,
  EDIT_WAREHOUSE_SUCCESS,
  FETCH_WAREHOUSE_FAILURE,
  FETCH_WAREHOUSE_REQUEST,
  FETCH_WAREHOUSE_SUCCESS,
  RESET_WAREHOUSE_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,

  dataFetched: false,
  isFetching: true,
  error: false,

  dataPosted: false,
  isPosting: false,
  creatingWarehouseVendor: false
};

export const warehousesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_WAREHOUSE_DATA:
      return initialState;

    case CREATE_WAREHOUSE_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case CREATE_WAREHOUSE_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case DELETE_WAREHOUSE_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case DELETE_WAREHOUSE_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case FETCH_WAREHOUSE_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_WAREHOUSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case EDIT_WAREHOUSE_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case EDIT_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case EDIT_WAREHOUSE_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    case CREATE_WAREHOUSE_VENDOR_REQUEST:
      return {
        ...state,
        creatingWarehouseVendor: true,
        error: false
      };
    case CREATE_WAREHOUSE_VENDOR_SUCCESS:
      return {
        ...state,
        creatingWarehouseVendor: false
      };
    case CREATE_WAREHOUSE_VENDOR_FAILURE:
      return {
        ...state,
        creatingWarehouseVendor: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default warehousesReducer;
