import {
  CREATE_COUPON_REQUEST,
  FETCH_COUPON_CHANGES_FAILURE,
  FETCH_COUPON_CHANGES_SUCCESS,
  FETCH_COUPON_OPTS_SUCCESS,
  RESET_COUPON_CHANGES
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  coupon: {},

  // COUPON FIELDS
  name: "",
  code: "",
  description: "",
  startDate: "",
  endDate: null,
  discountType: "",
  status: "",
  discountAmount: "",
  discountPercent: "",
  discountUpto: "",
  paymentProviders: [],
  paymentModes: [],
  platforms: [],
  customerGroups: [],
  roles: [],
  categories: [],
  categoryNames: [],
  subCategories: [],
  subCategoryNames: [],
  brands: [],
  activeHourStart: "00:00:00",
  activeHourEnd: "00:00:00",
  activeDays: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
  currentPurchaseAmtCaps: [],
  currentPurchaseAmtCapCtr: 1,
  currentPurchaseAmtCapMinVal: -1,
  currentPurchaseAmtCapMaxVal: -1,
  currentPurchaseAmtCapPer: "ALL",
  currentPurchaseAmtCapPerList: [],
  usageHistoryCaps: [],
  usageHistoryCapCtr: 1,
  usageHistoryCapUsageType: "ORDER_HISTORY",
  usageHistoryCapMinVal: -1,
  usageHistoryCapMaxVal: -1,
  usageHistoryCapInterval: "forever",
  usageHistoryCapIntervalPeriod: 1,
  usageHistoryCapPerUser: "GLOBAL",
  usageHistoryCapPerCoupon: "OVERALL",
  lcCount: 1,
  locationClusters: [],
  ruleChain: {
    _AND: []
  },

  // COUPON OPTIONS
  couponStatusesOpt: [],
  discountTypesOpt: [],
  paymentModesOpt: {},
  paymentProvidersOpt: [],
  customerGroupsOpt: [],
  categoriesOpt: [],
  subCategoriesOpt: {},
  brandsOpt: [],
  locationClustersOpt: [],
  isCreating: false,
  isFetching: false,
  isUpdating: false,

  // Error
  error: ""
};

export const createCouponReducer = createReducer(initialState, {
  [FETCH_COUPON_OPTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreating: false,
      isUpdating: false,
      error: ""
    };
  },
  [FETCH_COUPON_CHANGES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreating: false,
      isUpdating: false,
      error: ""
    };
  },
  [FETCH_COUPON_CHANGES_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreating: false,
      isUpdating: false
    };
  },
  [CREATE_COUPON_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [RESET_COUPON_CHANGES]: () => {
    return {
      ...initialState
    };
  }
});

export default createCouponReducer;
