import {
  FETCH_SHIPMENT_INSTANCES_FAILURE,
  FETCH_SHIPMENT_INSTANCES_REQUEST,
  FETCH_SHIPMENT_INSTANCES_SUCCESS,
  POST_TRACKING_FORM_FAILURE,
  POST_TRACKING_FORM_REQUEST,
  POST_TRACKING_FORM_SUCCESS,
  RESET_SHIPMENT_INSTANCES_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: [],
  dataFetched: false,
  isFetching: false,

  trackingFormPosted: false,
  error: false
};

export const shipmentInstancesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SHIPMENT_INSTANCES_DATA:
      return initialState;

    case FETCH_SHIPMENT_INSTANCES_REQUEST:
      return {
        ...state,
        dataFetched: false,
        trackingFormPosted: false,
        isFetching: true,
        error: false
      };
    case FETCH_SHIPMENT_INSTANCES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_SHIPMENT_INSTANCES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case POST_TRACKING_FORM_REQUEST:
      return {
        ...state,
        trackingFormPosted: false
      };

    case POST_TRACKING_FORM_SUCCESS:
      return {
        ...state,
        trackingFormPosted: true
      };

    case POST_TRACKING_FORM_FAILURE:
      return {
        ...state,
        trackingFormPosted: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default shipmentInstancesReducer;
