import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBJtQrBgxOvTDiJlKPt9QAgo1KjdxR_vWA",
  authDomain: "business-namaste.firebaseapp.com",
  databaseURL: "https://business-namaste-default-rtdb.firebaseio.com",
  projectId: "business-namaste",
  storageBucket: "business-namaste.appspot.com",
  messagingSenderId: "430884876971",
  appId: "1:430884876971:web:86c202f6714c2c445ffdee",
  measurementId: "G-1ZZJFTH20L"
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
