import {
  FETCH_SHIPMENT_FAILURE,
  FETCH_SHIPMENT_REQUEST,
  FETCH_SHIPMENT_SUCCESS,
  RESET_SHIPMENT_DATA,
  UPDATE_SHIPMENT_ADDRESS_FAILURE,
  UPDATE_SHIPMENT_ADDRESS_REQUEST,
  UPDATE_SHIPMENT_ADDRESS_SUCCESS,
  UPDATE_SHIPMENT_FAILURE,
  UPDATE_SHIPMENT_REQUEST,
  UPDATE_SHIPMENT_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  data: null,
  dataFetched: false,
  isFetching: false,
  error: false,
  isPosting: false,
  dataPosted: false
};

export const shipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SHIPMENT_DATA:
      return initialState;

    case FETCH_SHIPMENT_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_SHIPMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_SHIPMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case UPDATE_SHIPMENT_REQUEST:
      return {
        ...state,
        isPosting: true,
        error: false
      };
    case UPDATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_SHIPMENT_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    case UPDATE_SHIPMENT_ADDRESS_REQUEST:
      return {
        ...state,
        isPosting: true,
        error: false
      };
    case UPDATE_SHIPMENT_ADDRESS_SUCCESS:
      return {
        ...state,
        isPosting: false
      };
    case UPDATE_SHIPMENT_ADDRESS_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default shipmentsReducer;
