import {
  FETCH_EXCHANGE_DATA_REQUEST,
  FETCH_EXCHANGE_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  exchange: {}
};

export const exchangeReducer = createReducer(initialState, {
  [FETCH_EXCHANGE_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      selectedBFQuantity: 0
    };
  },
  [FETCH_EXCHANGE_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  }
});

export default exchangeReducer;
