import _find from "lodash/find";

import {
  CHECKBOX_FOR_IAR_PICKUP,
  FETCH_IAR_FOR_PICKUP_DATA_REQUEST,
  FETCH_IAR_FOR_PICKUP_DATA_SUCCESS,
  FETCH_INVENTORY_ADDITIONS_DATA_REQUEST,
  FETCH_INVENTORY_ADDITIONS_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  inventoryAdditionRequests: [],
  page: 1,
  status: "pending",
  type: "all",
  iarCount: 0,
  inventoryRequestsPickup: [],
  isAllChecked: false
};

export const inventoryAdditionRequestsReducer = createReducer(initialState, {
  [FETCH_INVENTORY_ADDITIONS_DATA_SUCCESS]: (state, payload) => {
    return { ...state, ...payload, isFetching: false };
  },
  [FETCH_INVENTORY_ADDITIONS_DATA_REQUEST]: (state, payload) => {
    return { ...state, ...payload, isFetching: true };
  },
  [FETCH_IAR_FOR_PICKUP_DATA_SUCCESS]: (state, payload) => {
    return { ...state, ...payload, isFetching: false };
  },
  [FETCH_IAR_FOR_PICKUP_DATA_REQUEST]: (state, payload) => {
    return { ...state, ...payload, isFetching: true };
  },
  [CHECKBOX_FOR_IAR_PICKUP]: (state, payload) => {
    const inventoryRequestsPickup = state.inventoryRequestsPickup;
    let isAllChecked = state.isAllChecked;
    switch (payload.op) {
      case "add":
        _find(inventoryRequestsPickup, { meta: payload.data.meta }).isChecked =
          true;
        break;
      case "remove":
        _find(inventoryRequestsPickup, { meta: payload.data.meta }).isChecked =
          false;
        break;
      case "all":
        inventoryRequestsPickup.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        inventoryRequestsPickup.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      inventoryRequestsPickup: [...inventoryRequestsPickup],
      isAllChecked
    };
  }
});

export default inventoryAdditionRequestsReducer;
