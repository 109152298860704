import {
  FETCH_REFERRALS_DATA_REQUEST,
  FETCH_REFERRALS_DATA_SUCCESS,
  FETCH_REFERRALS_USER_DATA_REQUEST,
  FETCH_REFERRALS_USER_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  users: {},
  referralUserList: {},
  isFetchingReferralUserList: false,
  usersCount: 0,
  referralUserListCount: 0,
  isFetching: true,
  userSearchResult: [],
  searchActive: false,
  showSearch: false,
  user: {},
  searchParams: {}
};

export const referralsReducer = createReducer(initialState, {
  [FETCH_REFERRALS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_REFERRALS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_REFERRALS_USER_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReferralUserList: true
    };
  },
  [FETCH_REFERRALS_USER_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReferralUserList: false
    };
  }
});

export default referralsReducer;
