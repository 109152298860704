import _find from "lodash/find";

import {
  CHECKBOX_FOR_BFS_SELLER_SHIPMENT,
  FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_REQUEST,
  FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_SUCCESS
} from "../../constants/sellers";
import { createReducer } from "../../utils";

const initialState = {
  isFetching: true,
  sellerAddress: {},
  buyerfulfilments: [],
  page: 1,
  count: 0,
  isAllChecked: false
};

export const sellerShipmentsReducer = createReducer(initialState, {
  [FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_BFS_FOR_SELLER_SHIPMENT_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [CHECKBOX_FOR_BFS_SELLER_SHIPMENT]: (state, payload) => {
    const buyerfulfilments = state.buyerfulfilments;
    let isAllChecked = state.isAllChecked;
    switch (payload.op) {
      case "add":
        _find(buyerfulfilments, { meta: payload.data.meta }).isChecked = true;
        break;
      case "remove":
        _find(buyerfulfilments, { meta: payload.data.meta }).isChecked = false;
        break;
      case "all":
        buyerfulfilments.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        buyerfulfilments.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      buyerfulfilments: [...buyerfulfilments],
      isAllChecked
    };
  }
});

export default sellerShipmentsReducer;
