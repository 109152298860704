import {
  CHANGE_ACCEPT_QUANTITY_IAR_CONFIRM,
  FETCH_INVENTORY_ADDITION_DATA_REQUEST,
  FETCH_INVENTORY_ADDITION_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  inventoryrequest: {},
  accepted: 0
};

export const inventoryAdditionRequestReducer = createReducer(initialState, {
  [FETCH_INVENTORY_ADDITION_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      accepted: 0
    };
  },
  [FETCH_INVENTORY_ADDITION_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [CHANGE_ACCEPT_QUANTITY_IAR_CONFIRM]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
});

export default inventoryAdditionRequestReducer;
