import {
  ALLOT_ROUTE_INSTANCES_FAILURE,
  ALLOT_ROUTE_INSTANCES_REQUEST,
  ALLOT_ROUTE_INSTANCES_SUCCESS,
  FETCH_ROUTE_INSTANCES_FAILURE,
  FETCH_ROUTE_INSTANCES_REQUEST,
  FETCH_ROUTE_INSTANCES_SUCCESS,
  RESET_ROUTE_INSTANCES_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    routeInstances: []
  },
  dataFetched: false,
  isFetching: false,
  error: false,
  dataPosted: false,
  isPosting: false
};

export const shipmentRoutePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ROUTE_INSTANCES_DATA:
      return initialState;

    case FETCH_ROUTE_INSTANCES_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_ROUTE_INSTANCES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_ROUTE_INSTANCES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case ALLOT_ROUTE_INSTANCES_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case ALLOT_ROUTE_INSTANCES_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ALLOT_ROUTE_INSTANCES_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default shipmentRoutePlanReducer;
