import React from "react";
import Select from "react-select";

import { getBasePath } from "../utils";

const SelectNav = props => {
  const { openLink, roles = [], location } = props;
  const getOptions = roles => {
    const optionsMap = {};
    roles.forEach(role => {
      switch (role) {
        case "admin":
          optionsMap.Admin = "/admin-dashboard";
          break;
        case "account.owner":
        case "account.admin":
          optionsMap.Account = "/account-dashboard";
          break;
        case "seller":
          optionsMap.Seller = "/dashboard";
          break;
        case "customerCare":
          optionsMap.Support = "/support-dashboard";
          break;
        case "themeManager":
          optionsMap["Theme Manager"] = "/theme-manager-dashboard";
          break;
        case "adminSupport":
          optionsMap["Admin Support"] = "/admin-support-dashboard";
          break;
        case "productEditor":
          optionsMap["Product Editor"] = "/product-editor-dashboard";
          break;
        default:
        //do nothing;
      }
    });
    const options = Object.keys(optionsMap).map(key => ({
      label: key,
      value: optionsMap[key]
    }));
    return options;
  };

  const handleChange = ({ value }) => {
    openLink(value, false, false);
  };

  return (
    <Select
      instanceId="select-nav"
      isClearable={false}
      searchable={false}
      value={getBasePath(location)}
      placeholder="Filter users"
      options={getOptions(roles)}
      onChange={handleChange}
      styles={{
        control: styles => ({
          ...styles,
          backgroundColor: "transparent",
          borderColor: "white",
          boxShadow: "none"
        }),
        indicatorSeparator: styles => ({ ...styles, display: "none" }),
        container: styles => ({
          ...styles,
          fontSize: 12,
          width: 150,
          margin: "5px auto 8px",
          boxShadow: "none",
          color: "white"
        }),
        menu: styles => ({
          ...styles,
          backgroundColor: "white",
          color: "black"
        })
      }}
      theme={{
        colors: {
          primary: "white"
        },
        borderRadius: 0,
        spacing: {
          baseUnit: 2,
          controlHeight: 28,
          menuGutter: 8
        }
      }}
    />
  );
};

export default SelectNav;
