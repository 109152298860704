import {
  BULK_UPDATE_PRODUCTS_PROGRESS,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  RESET_PRODUCTS,
  UPDATE_BULK_PRODUCT_REQUEST,
  UPDATE_BULK_PRODUCT_SUCCESS,
  UPDATE_SELLER_INVENTORY_DATA,
  UPDATE_SELLER_INVENTORY_REQUEST
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  isUpdating: false,
  products: [],
  query: {},
  aggregations: [],
  total: 0,
  text: "",
  filters: {},
  params: {},
  page: 1,
  sort: "",
  size: 48,
  noFilter: false,
  updatingProduct: [],
  bulkUpdates: {}
};

export const productsReducer = createReducer(initialState, {
  [FETCH_PRODUCTS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_PRODUCTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [RESET_PRODUCTS]: () => {
    return initialState;
  },
  [BULK_UPDATE_PRODUCTS_PROGRESS]: (state, payload) => {
    return {
      ...state,
      bulkUpdates: payload
    };
  },
  [UPDATE_BULK_PRODUCT_REQUEST]: state => {
    return {
      ...state,
      isUpdating: true
    };
  },
  [UPDATE_BULK_PRODUCT_SUCCESS]: state => {
    return {
      ...state,
      isUpdating: false
    };
  },
  [UPDATE_SELLER_INVENTORY_REQUEST]: (state, payload) => {
    const { productId } = payload;
    const products = state.products.map(product => {
      if (product.Product._id === productId) {
        return {
          ...product,
          updatingInventory: true
        };
      }
      return product;
    });
    return {
      ...state,
      products
    };
  },
  [UPDATE_SELLER_INVENTORY_DATA]: (state, payload) => {
    const { productId, warehouseId, quantity } = payload;
    const products = state.products.map(product => {
      if (product.Product._id === productId) {
        const inventory = product.inventory || [];
        const index = inventory.findIndex(i => {
          return i.warehouseId == warehouseId;
        });
        const newInvetory =
          index == -1
            ? [
                ...inventory,
                {
                  id: 0,
                  warehouseId: warehouseId,
                  productId: productId,
                  inventoryQuantity: quantity,
                  orderedQuantity: 0,
                  returnQuantity: 0
                }
              ]
            : inventory.map(i => {
                if (i.warehouseId == warehouseId) {
                  return {
                    ...i,
                    inventoryQuantity: i.inventoryQuantity + quantity
                  };
                }
                return i;
              });
        return {
          ...product,
          inventory: newInvetory,
          updatingInventory: false
        };
      }
      return product;
    });
    return {
      ...state,
      products
    };
  }
});

export default productsReducer;
