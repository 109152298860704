import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import Footer from "../../components/Footer";
import SectionHeader from "../../components/SectionHeader";
import logoWhite from "../../images/prokure_logo_white_with_text.png";

const FrontPage = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? logoWhite;
  return (
    <div className="frontpage">
      <SectionHeader logo={logo} />
      <div className="info-section-wrapper light bg">
        <div
          style={{
            width: "100vw",
            backgroundColor: "#fff",
            padding: "0px 80px 0px 80px"
          }}>
          <div
            className="horizontal-banner-view"
            style={{
              padding: 10,
              marginTop: 120,
              backgroundImage:
                "url(' https://res.cloudinary.com/prokure/image/upload/c_scale,q_100,w_680/v1540286789/websiteData/illustration_3x.png')",
              backgroundPosition: "right center"
            }}>
            <div className="horizontal-section">
              <div className="content-inset">
                <h1
                  style={{
                    color: "#1b1b1b",
                    marginBottom: "2rem",
                    fontSize: 36,
                    fontFamily: "Montserrat"
                  }}>
                  Grow with powerful mobile first B2B e-commerce platform
                </h1>
                <p>
                  B2B e-commerce is at an inflection point. By 2020, the size of
                  U.S B2B eCommerce market is expected to be twice that f B2C
                  eCommerce. Busniesses are fast shifting to B2B eCommerce but
                  customer expectations are also higher than ever. Namaste is a
                  SaaS platform for running a B2B Business.
                </p>
              </div>
            </div>
            <div className="horizontal-section" />
          </div>

          <div
            className="horizontal-banner-view"
            style={{
              padding: 20,
              marginTop: 120,
              backgroundImage:
                "url('https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png')",
              backgroundPosition: "center left"
            }}>
            <div className="horizontal-section" />
            <div className="horizontal-section">
              <div className="content-inset">
                <h1 className="title">Mobile First</h1>
                <p>
                  Business buyers are also consumers who have experienced B2C
                  ecommerce and they expect the same convenient shopping
                  experience in their B2B ecommerce platform. <br />
                  <span style={{ color: "brown", fontWeight: "400" }}>
                    Mobile first and an amazon like shopping experience.
                  </span>
                  <br />
                  <br />
                  In Namaste, you can use ALL the features from your mobile app.
                  Built for a mobile first world.
                </p>
                <div className="content-action-div">
                  <div className="btn">
                    <Link to="/mobile-first">
                      <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="horizontal-banner-view"
            style={{
              padding: 20,
              marginTop: 120,
              backgroundImage:
                "url('https://res.cloudinary.com/prokure/image/upload/v1540159281/websiteData/b2b_3x.png')",
              backgroundPosition: "right center"
            }}>
            <div className="horizontal-section">
              <div className="content-inset">
                <h1 className="title">All the complicated B2B use cases</h1>
                <p>
                  B2B ecommerce is a lot more complicated than B2C ecommerce.
                  <span style={{ color: "green", fontWeight: "400" }}>
                    Multi-tier distribution selling, geo-fencing, advanced
                    pricing rules, fully customizable order and payment flows
                    across all layers of supply chain, on the ground logistics
                    and sales management etc.
                  </span>
                  With Namaste you can manage all the complex B2B business
                  requirements effortlessly.
                </p>
                <div className="content-action-div">
                  <div className="btn">
                    <Link to="/feature-list">
                      <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal-section" />
          </div>
          <div
            className="horizontal-banner-view"
            style={{
              padding: 20,
              margin: "120px 0px",
              backgroundImage:
                "url('https://res.cloudinary.com/prokure/image/upload/v1540163681/websiteData/mobile-first_3x.png')",
              backgroundPosition: "center left"
            }}>
            <div className="horizontal-section" />
            <div className="horizontal-section">
              <div className="content-inset">
                <h1 className="title">Developer First</h1>
                <p>
                  Commerce is essentailly movement of goods from A to B and
                  movement of payment in the opposite direction. But between A
                  and B, it can take varied forms (marketplace, own inventory,
                  drop shipping etc.
                  <br />
                  <br />
                  <span style={{ color: "brown", fontWeight: "400" }}>
                    In Namaste, you can use ALL the features from your mobile
                    app. Built for a mobile first world.
                  </span>
                </p>

                <div className="content-action-div">
                  <div className="btn" onClick={scrollToTop}>
                    <Link to="/developer-first">
                      <span>Learn More</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FrontPage;
