import {
  FETCH_PAYMENT_DATA_REQUEST,
  FETCH_PAYMENT_DATA_SUCCESS,
  RESET_PAYMENT_DATA,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  isUpdating: false,
  payment: {}
};

export const paymentReducer = createReducer(initialState, {
  [FETCH_PAYMENT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_PAYMENT_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [UPDATE_PAYMENT_REQUEST]: state => {
    return {
      ...state,
      isUpdating: true
    };
  },
  [UPDATE_PAYMENT_SUCCESS]: state => {
    return {
      ...state,
      isUpdating: false
    };
  },
  [RESET_PAYMENT_DATA]: () => {
    return initialState;
  }
});

export default paymentReducer;
