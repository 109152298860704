import {
  FETCH_THIRD_PARTY_SHIPMENT_TRACKING_FAILURE,
  FETCH_THIRD_PARTY_SHIPMENT_TRACKING_REQUEST,
  FETCH_THIRD_PARTY_SHIPMENT_TRACKING_SUCCESS,
  RESET_TRACKING_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  dataFetched: false,
  isFetching: false,
  error: false
};

export const trackingReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_TRACKING_DATA:
      return initialState;

    case FETCH_THIRD_PARTY_SHIPMENT_TRACKING_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_THIRD_PARTY_SHIPMENT_TRACKING_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_THIRD_PARTY_SHIPMENT_TRACKING_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default trackingReducer;
