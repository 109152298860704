import { loadableReady } from "@loadable/component";
import React from "react";
import { hydrateRoot } from "react-dom/client";

import RootContainer from "./rootContainer.js";

const domNode = document.getElementById("root");

loadableReady(() => {
  hydrateRoot(domNode, <RootContainer />);
});

const IS_PROD = process.env.NODE_ENV === "production";

if (IS_PROD && "serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then(registration => {
        console.log("SW registered: ", registration);
      })
      .catch(registrationError => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}

if (module.hot) {
  module.hot.accept("./rootContainer.js", () => {
    const NextRootContainer = require("./rootContainer.js").default;
    loadableReady(() => {
      hydrateRoot(domNode, <NextRootContainer />);
    });
  });
}
