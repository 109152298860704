import { onValue, ref, remove } from "firebase/database";

import {
  BULK_UPDATE_PRODUCTS_PROGRESS,
  GET_ACTIVE_CALLS
} from "../../constants/actionTypes";
import { database } from "../../utils/firebase";

export const getBulkProductUpdateProgress = () => (dispatch, state) => {
  onValue(
    ref(database, `userData/${state().auth.user._id}/bulkUpdates/products`),
    data => {
      dispatch({
        type: BULK_UPDATE_PRODUCTS_PROGRESS,
        payload: {
          products: data.val()
        }
      });
    }
  );
};

export const getActiveCalls = () => (dispatch, state) => {
  onValue(
    ref(database, `userData/${state().auth.user._id}/customer-call`),
    data => {
      dispatch({
        type: GET_ACTIVE_CALLS,
        payload: {
          calls: data.val()
        }
      });
    }
  );
};

export const closeCall = callId => (dispatch, state) => {
  remove(
    ref(database, `userData/${state().auth.user._id}/customer-call/${callId}`)
  );
};
