import {
  FETCH_USER_VERIFICATIONS_FAILURE,
  FETCH_USER_VERIFICATIONS_REQUEST,
  FETCH_USER_VERIFICATIONS_SUCCESS,
  RESET_USER_VERIFICATIONS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    verifications: []
  },
  dataFetched: false,
  isFetching: false,
  error: false
};

export const userVerificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_USER_VERIFICATIONS_DATA:
      return {
        ...state,
        data: {
          count: 0,
          verifications: []
        },
        dataFetched: false,
        isFetching: false,
        error: false
      };

    case FETCH_USER_VERIFICATIONS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_USER_VERIFICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_USER_VERIFICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default userVerificationsReducer;
