import {
  FETCH_PRODUCTS_FOR_SHIPMENT,
  FETCH_PRODUCTS_FOR_SHIPMENT_SUCCESS
} from "../../constants/sellers";
import { createReducer } from "../../utils";

const initialState = {
  isFetching: false,
  count: 0,
  shipments: [],
  searchParams: {},
  page: 1
};

export const shipmentProductsReducer = createReducer(initialState, {
  [FETCH_PRODUCTS_FOR_SHIPMENT]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_PRODUCTS_FOR_SHIPMENT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  }
});

export default shipmentProductsReducer;
