import {
  FETCH_IMAGES_REQUEST,
  FETCH_IMAGES_SUCCESS,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialWalletState = {
  page: 1,
  count: 0,
  isFetching: true,
  images: [],
  isPosting: false
};

export const imagesReducer = createReducer(initialWalletState, {
  [FETCH_IMAGES_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_IMAGES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [UPLOAD_IMAGE_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isPosting: true
    };
  },
  [UPLOAD_IMAGE_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isPosting: false
    };
  }
});

export default imagesReducer;
