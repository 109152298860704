import {
  EXPORT_TRANSLATIONS_FAILURE,
  EXPORT_TRANSLATIONS_REQUEST,
  EXPORT_TRANSLATIONS_SUCCESS,
  FETCH_TRANSLATION_FAILURE,
  FETCH_TRANSLATION_REQUEST,
  FETCH_TRANSLATION_SUCCESS,
  FETCH_TRANSLATIONS_REQUEST,
  FETCH_TRANSLATIONS_SUCCESS,
  IMPORT_TRANSLATIONS_FAILURE,
  IMPORT_TRANSLATIONS_REQUEST,
  IMPORT_TRANSLATIONS_SUCCESS,
  UPDATE_TRANSLATION_FAILURE,
  UPDATE_TRANSLATION_REQUEST,
  UPDATE_TRANSLATION_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  translations: [],
  filenames: [],
  tags: [],
  count: 0,
  isFetching: true,
  isTranslation: false,
  error: false,
  isCreatingOrUpdating: false,
  updateData: {},
  exporting: false,
  exportURL: null,
  importing: false,
  importSuccess: false,
  translation: {}
};

export const translationsReducer = createReducer(initialState, {
  [FETCH_TRANSLATION_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isTranslating: true
    };
  },
  [FETCH_TRANSLATION_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isTranslating: false
    };
  },
  [FETCH_TRANSLATION_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isTranslating: false,
      translation: payload.translations,
      key: payload.key
    };
  },
  [FETCH_TRANSLATIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_TRANSLATIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [UPDATE_TRANSLATION_REQUEST]: (state, payload) => {
    const translations = state.translations.map(t => {
      if (t.key == payload.key) {
        t.translations[payload.locale] = payload.value;
      }
      return t;
    });
    return {
      ...state,
      translations,
      isCreatingOrUpdating: true,
      error: false,
      updateData: payload
    };
  },
  [UPDATE_TRANSLATION_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_TRANSLATION_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      updateData: {},
      error: payload.error
    };
  },
  [EXPORT_TRANSLATIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: false,
      exportURL: null,
      exporting: true
    };
  },
  [EXPORT_TRANSLATIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: false,
      exportURL: payload.url,
      exporting: false
    };
  },
  [EXPORT_TRANSLATIONS_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: payload,
      exportURL: null,
      exporting: false
    };
  },
  [IMPORT_TRANSLATIONS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: false,
      importing: true,
      importSuccess: false
    };
  },
  [IMPORT_TRANSLATIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: false,
      importing: false,
      importSuccess: true
    };
  },
  [IMPORT_TRANSLATIONS_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      error: payload,
      importing: false,
      importSuccess: false
    };
  }
});

export default translationsReducer;
