import {
  CREATE_PAYOUT_REQUEST,
  FETCH_LEDGER_DATA_REQUEST,
  FETCH_LEDGER_DATA_SUCCESS,
  FETCH_LEDGER_USERS_DATA_REQUEST,
  FETCH_LEDGER_USERS_DATA_SUCCESS,
  FETCH_PAYOUT_REQUEST_DATA_SUCCESS,
  RESET_LEDGER_DATA
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  userPage: 1,
  users: [],
  userCount: 0,
  isFetching: false,
  page: 1,
  count: 0,
  isFetchingLedger: false,
  user: {},
  sellerInfo: {},
  ledgers: [],
  openingBalance: 0,
  isCreatingPayout: false
};

export const ledgerReducer = createReducer(initialState, {
  [FETCH_LEDGER_USERS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_LEDGER_USERS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_LEDGER_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingLedger: true
    };
  },
  [FETCH_LEDGER_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingLedger: false
    };
  },
  [CREATE_PAYOUT_REQUEST]: state => {
    return {
      ...state,
      isCreatingPayout: true
    };
  },
  [FETCH_PAYOUT_REQUEST_DATA_SUCCESS]: state => {
    return {
      ...state,
      isCreatingPayout: false
    };
  },
  [RESET_LEDGER_DATA]: state => {
    return {
      ...state,
      page: 1,
      count: 0,
      ledgers: []
    };
  }
});

export default ledgerReducer;
