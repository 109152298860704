import {
  CREATE_SELLER_REQUEST,
  CREATE_SELLER_SUCCESS,
  FETCH_USER_DATA_REQUEST,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_ORDERS_DATA_REQUEST,
  FETCH_USER_ORDERS_DATA_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  users: [],
  orders: [],
  orderCount: 0,
  page: 1,
  isOrderFetching: false,
  isFetching: false,
  isSellerCreating: false,
  actionPage: 0
};

export const userReducer = createReducer(initialState, {
  [FETCH_USER_DATA_SUCCESS]: (state, payload) => {
    const users = state.users.filter(user => user.page != payload.page);
    users.push(payload);
    return {
      ...state,
      users: [...users],
      isFetching: false
    };
  },
  [FETCH_USER_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [UPDATE_USER_DATA_REQUEST]: (state, payload) => {
    const users = state.users.map(user => {
      if (user.page == payload.page) {
        user.isUpdating = true;
      }
      return user;
    });
    return {
      ...state,
      users: [...users]
    };
  },
  [CREATE_SELLER_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isSellerCreating: true
    };
  },

  [CREATE_SELLER_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isSellerCreating: false
    };
  },
  [UPDATE_USER_DATA_SUCCESS]: (state, payload) => {
    const users = state.users.map(user => {
      if (user.page == payload.page) {
        const userJSON = user.user.user;
        Object.keys(payload.data.user).forEach(key => {
          userJSON[key] = payload.data.user[key];
        });
        user.user = userJSON;
        user = {
          ...user,
          isUpdating: false,
          error: ""
        };
        if (payload.data.userSellers) {
          user.user.userSellers = payload.data.userSellers || [];
        }
      }
      return user;
    });
    return {
      ...state,
      users: [...users]
    };
  },
  [UPDATE_USER_DATA_FAILURE]: (state, payload) => {
    const users = state.users.map(user => {
      if (user.page == payload.page) {
        user = user.mergeDeep({
          isUpdating: false,
          error: payload.error
        });
      }
      return user;
    });
    return {
      ...state,
      users: [...users]
    };
  },
  [FETCH_USER_ORDERS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isOrderFetching: true
    };
  },
  [FETCH_USER_ORDERS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isOrderFetching: false
    };
  }
});

export default userReducer;
