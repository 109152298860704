import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: null,
  info: null,
  firebaseToken: null,
  userRealtimeData: {},
  accountRealtimeData: {},
  error: false,
  pincodeData: {},
  session: {},
  isFetching: false,
  isPincodeFetching: false,
  updatingSettings: false
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authRequest: state => {
      state.isAuthenticating = true;
      state.error = false;
    },
    authRequestSuccess: state => {
      state.isAuthenticating = false;
      state.error = false;
    },
    authRequestFailure: (state, action) => {
      state.isAuthenticating = false;
      state.error = action.payload;
    },
    loginUserSuccess: (state, action) => {
      state.isAuthenticating = false;
      state.isAuthenticated = true;
      state.user_id = action.payload.user._id;
      state.user = action.payload.user;
      state.info = action.payload.info;
      state.firebaseToken = action.payload.firebaseToken;
      state.error = false;
    },
    receiveUserRealtimeData: (state, action) => {
      state.userRealtimeData = action.payload;
    },
    receiveAccountRealtimeData: (state, action) => {
      state.accountRealtimeData = action.payload;
    },
    pincodeRequest: state => {
      state.isPincodeFetching = true;
      state.pincodeData = {};
    },
    pincodeRequestSuccess: (state, action) => {
      state.isPincodeFetching = false;
      state.pincodeData = action.payload;
    },
    addPublicUserDetailsRequest: state => {
      state.isFetching = true;
      state.error = false;
    },
    addPublicUserDetailsSuccess: (state, action) => {
      state.isFetching = false;
      state.session = action.payload;
      state.error = false;
    },
    addPublicUserDetailsFailure: state => {
      state.isFetching = false;
      state.error = true;
    }
  }
});

export const {
  authRequest,
  authRequestSuccess,
  authRequestFailure,
  loginUserSuccess,
  receiveUserRealtimeData,
  receiveAccountRealtimeData,
  pincodeRequest,
  pincodeRequestSuccess,
  addPublicUserDetailsRequest,
  addPublicUserDetailsSuccess,
  addPublicUserDetailsFailure
} = authSlice.actions;

export default authSlice.reducer;
