import {
  GET_SPOT_INSTANCES_FAILURE,
  GET_SPOT_INSTANCES_REQUEST,
  GET_SPOT_INSTANCES_SUCCESS,
  PAUSE_SPOT_INSTANCE_FAILURE,
  PAUSE_SPOT_INSTANCE_REQUEST,
  PAUSE_SPOT_INSTANCE_SUCCESS,
  RESUME_SPOT_INSTANCE_FAILURE,
  RESUME_SPOT_INSTANCE_REQUEST,
  RESUME_SPOT_INSTANCE_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  instances: [],
  isFetching: false,
  error: null
};

export const spotInstancesReducer = createReducer(initialState, {
  [GET_SPOT_INSTANCES_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [GET_SPOT_INSTANCES_FAILURE]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      error: payload
    };
  },
  [GET_SPOT_INSTANCES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      error: null,
      instances: payload
    };
  },
  [PAUSE_SPOT_INSTANCE_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [PAUSE_SPOT_INSTANCE_FAILURE]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      error: payload
    };
  },
  [PAUSE_SPOT_INSTANCE_SUCCESS]: state => {
    return {
      ...state,
      isFetching: false,
      error: null
    };
  },
  [RESUME_SPOT_INSTANCE_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [RESUME_SPOT_INSTANCE_FAILURE]: (state, payload) => {
    return {
      ...state,
      isFetching: false,
      error: payload
    };
  },
  [RESUME_SPOT_INSTANCE_SUCCESS]: state => {
    return {
      ...state,
      isFetching: false,
      error: null
    };
  }
});

export default spotInstancesReducer;
