import {
  FETCH_SUBSCRIPTION_PLANS_REQUEST,
  FETCH_SUBSCRIPTION_PLANS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  subscriptionPlans: [],
  isFetching: true,
  isCreatingOrUpdating: false,
  error: false
};

export const subscriptionPlansReducer = createReducer(initialState, {
  [FETCH_SUBSCRIPTION_PLANS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      error: false
    };
  },
  [FETCH_SUBSCRIPTION_PLANS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  }
});

export default subscriptionPlansReducer;
