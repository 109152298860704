import {
  CREATE_OR_UPDATE_CASHBACK_CLUSTERS_REQUEST,
  FETCH_CASHBACK_CLUSTERS_REQUEST,
  FETCH_CASHBACK_CLUSTERS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  data: [],
  isFetching: true,
  isCreatingOrUpdating: false
};

export const cashbackRulesReducer = createReducer(initialState, {
  [FETCH_CASHBACK_CLUSTERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_OR_UPDATE_CASHBACK_CLUSTERS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [FETCH_CASHBACK_CLUSTERS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreatingOrUpdating: false
    };
  }
});

export default cashbackRulesReducer;
