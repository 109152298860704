import {
  FETCH_TRIP_FAILURE,
  FETCH_TRIP_REQUEST,
  FETCH_TRIP_SUCCESS,
  UPDATE_TRIP_BATTERY_DATA,
  UPDATE_TRIP_LOCATION_DATA
} from "../constants/actionTypes";

const initialData = {
  battery: {},
  location: {}
};

const initialState = {
  data: initialData,
  tripId: "",
  dataFetched: false,
  isFetching: false,
  error: ""
};

export const tripReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRIP_REQUEST:
      return {
        ...state,
        tripId: action.payload.tripId,
        dataFetched: false,
        isFetching: true,
        data: initialData,
        error: false
      };
    case FETCH_TRIP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: { ...state.data, ...action.payload.response },
        tripId: action.payload.tripId
      };
    case FETCH_TRIP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case UPDATE_TRIP_BATTERY_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          battery: {
            ...state.data.battery,
            [action.payload.key]: action.payload.value
          }
        }
      };
    }
    case UPDATE_TRIP_LOCATION_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          location: {
            ...state.data.location,
            [action.payload.key]: action.payload.value
          }
        }
      };
    }
    default:
      return state;
  }
};

export default tripReducer;
