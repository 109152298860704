import {
  FETCH_CANCELLATIONS_DATA_REQUEST,
  FETCH_CANCELLATIONS_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  cancellations: [],
  cancelCount: 0,
  isFetching: false
};

export const cancellationsReducer = createReducer(initialState, {
  [FETCH_CANCELLATIONS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_CANCELLATIONS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  }
});

export default cancellationsReducer;
