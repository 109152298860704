import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./reducers";
import { initialState as initialAuthState } from "./reducers/auth";

export const configureAppStore = preloadedState => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    devTools: process.env.NODE_ENV !== "production"
  });
  return store;
};

export const createStore = (sessionData, userData) => {
  const initialState = {
    auth: {
      ...initialAuthState,
      ...(userData
        ? {
            isAuthenticating: false,
            isAuthenticated: true,
            user_id: userData._id,
            user: userData,
            error: false
          }
        : {}),
      session: sessionData
    }
  };

  return configureAppStore(initialState);
};
