import {
  ADD_DATA_FOR_PACKAGING_SLIPS,
  CREATE_DELIVERY_VENDOR_FAILURE,
  CREATE_DELIVERY_VENDOR_REQUEST,
  CREATE_DELIVERY_VENDOR_SUCCESS,
  FETCH_DELIVERY_VENDORS_FAILURE,
  FETCH_DELIVERY_VENDORS_REQUEST,
  FETCH_DELIVERY_VENDORS_SUCCESS,
  FETCH_PACKAGING_SLIPS_FAILURE,
  FETCH_PACKAGING_SLIPS_REQUEST,
  FETCH_PACKAGING_SLIPS_SUCCESS,
  RESET_DELIVERY_VENDORS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    deliveryVendors: []
  },
  dataFetched: false,
  payload: {},
  packagingSlips: {},
  isCreating: false,
  isFetching: false,
  error: false
};

export const deliveryVendorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DELIVERY_VENDORS_DATA:
      return {
        ...state,
        data: {
          count: 0,
          deliveryVendors: []
        },
        dataFetched: false,
        isFetching: false,
        error: false
      };

    case FETCH_DELIVERY_VENDORS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_DELIVERY_VENDORS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_DELIVERY_VENDORS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case CREATE_DELIVERY_VENDOR_REQUEST:
      return {
        ...state,
        isCreating: true,
        error: false
      };
    case CREATE_DELIVERY_VENDOR_SUCCESS:
      return {
        ...state,
        isCreating: false,
        error: false
      };
    case CREATE_DELIVERY_VENDOR_FAILURE:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };
    case ADD_DATA_FOR_PACKAGING_SLIPS:
      return {
        ...state,
        payload: action.payload
      };

    case FETCH_PACKAGING_SLIPS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        dataFetched: false
      };
    case FETCH_PACKAGING_SLIPS_SUCCESS:
      return {
        ...state,
        dataFetched: true,
        isFetching: false,
        packagingSlips: action.payload
      };
    case FETCH_PACKAGING_SLIPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      };

    default:
      return state;
  }
};

export default deliveryVendorsReducer;
