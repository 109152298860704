import {
  FETCH_PRODUCT_SUGGESTIONS_REQUEST,
  FETCH_PRODUCT_SUGGESTIONS_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  suggestions: []
};

export const productSuggestionsReducer = createReducer(initialState, {
  [FETCH_PRODUCT_SUGGESTIONS_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_PRODUCT_SUGGESTIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      suggestions: {
        ...state.suggestions,
        [payload.text]: payload.suggestions
      },
      isFetching: false
    };
  }
});

export default productSuggestionsReducer;
