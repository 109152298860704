import {
  FETCH_SCANCODES_FAILURE,
  FETCH_SCANCODES_REQUEST,
  FETCH_SCANCODES_SUCCESS,
  RESET_SCANCODES,
  UPDATE_SCANCODES_REQUEST,
  UPDATE_SCANCODES_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  scancodes: {}
};

export const initialScancodeState = {
  page: 1,
  scancodes: [],
  count: 0,
  isFetching: false,
  isUpdating: false,
  error: null
};

export const scancodesReducer = createReducer(initialState, {
  [FETCH_SCANCODES_REQUEST]: (state, payload = {}) => {
    const page = payload.page || 0;
    const scancode = state.scancodes[page] || initialScancodeState;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...scancode,
          isFetching: true
        }
      }
    };
  },
  [FETCH_SCANCODES_SUCCESS]: (state, payload = {}) => {
    const page = payload.page || 0;
    const scancode = state.scancodes[page] || initialScancodeState;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...scancode,
          ...payload.response,
          isFetching: false
        }
      }
    };
  },
  [FETCH_SCANCODES_FAILURE]: (state, payload = {}) => {
    const page = payload.page || 0;
    const scancode = state.scancodes[page] || initialScancodeState;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...scancode,
          error: payload.error,
          isFetching: false
        }
      }
    };
  },
  [RESET_SCANCODES]: (state, payload = {}) => {
    const page = payload.page || 0;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...initialScancodeState
        }
      }
    };
  },
  [UPDATE_SCANCODES_REQUEST]: (state, payload = {}) => {
    const page = payload.page || 0;
    const scancode = state.scancodes[page] || initialScancodeState;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...scancode,
          isUpdating: true
        }
      }
    };
  },
  [UPDATE_SCANCODES_SUCCESS]: (state, payload = {}) => {
    const page = payload.page || 0;
    const scancode = state.scancodes[page] || initialScancodeState;
    return {
      ...state,
      scancodes: {
        ...state.scancodes,
        [page]: {
          ...scancode,
          isUpdating: false
        }
      }
    };
  }
});

export default scancodesReducer;
