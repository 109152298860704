import {
  FETCH_PAYMENTS_DATA_REQUEST,
  FETCH_PAYMENTS_DATA_SUCCESS,
  RESET_PAYMENTS_DATA
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  payments: {},
  isFetching: false
};

export const initialPaymentState = {
  page: 1,
  payments: [],
  isFetching: false
};

export const paymentsReducer = createReducer(initialState, {
  [FETCH_PAYMENTS_DATA_REQUEST]: (state, payload = {}) => {
    const page = payload.page || 0;
    const payment = state.payments[page] || initialPaymentState;
    return {
      ...state,
      payments: {
        ...state.payments,
        [page]: {
          ...payment,
          isFetching: true
        }
      }
    };
  },
  [FETCH_PAYMENTS_DATA_SUCCESS]: (state, payload = {}) => {
    const page = payload.page || 0;
    const payment = state.payments[page] || initialPaymentState;
    return {
      ...state,
      payments: {
        ...state.payments,
        [page]: {
          ...payment,
          ...payload.response,
          isFetching: false
        }
      }
    };
  },
  [RESET_PAYMENTS_DATA]: (state, payload = {}) => {
    const page = payload.page || 0;
    return {
      ...state,
      payments: {
        ...state.payments,
        [page]: {
          ...initialPaymentState
        }
      }
    };
  }
});

export default paymentsReducer;
