import _find from "lodash/find";

import {
  CHANGE_ASSIGN_SELLER_PROCESS_PICKUP,
  CHANGE_FIELDS_PROCESS_PICKUP,
  FETCH_PROCESS_PICKUP_DATA_REQUEST,
  FETCH_PROCESS_PICKUP_DATA_SUCCESS,
  PUSH_TO_FINAL_REQUEST_FOR_PROCESS_PICKUP,
  UPDATE_SFS_FIELDS_PROCESS_PICKUP
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  accepted: 0,
  inventoryrequest: {},
  inventoryrequestIds: [],
  acceptSellerFulfilments: {},
  totalAcceptedAssigned: 0,
  isAllChecked: false,
  finalRequest: [],
  assignedSeller: { name: "" },
  pickupProductId: null
};

export const processPickupReducer = createReducer(initialState, {
  [FETCH_PROCESS_PICKUP_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_PROCESS_PICKUP_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      totalAcceptedAssigned: 0,
      isAllChecked: false,
      finalRequest: []
    };
  },
  [CHANGE_FIELDS_PROCESS_PICKUP]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [CHANGE_ASSIGN_SELLER_PROCESS_PICKUP]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },

  [UPDATE_SFS_FIELDS_PROCESS_PICKUP]: (state, payload) => {
    const acceptSellerFulfilments = state.acceptSellerFulfilments;
    let isAllChecked = state.isAllChecked;
    const acceptedFulfilment = _find(acceptSellerFulfilments, {
      id: payload.sfid
    });
    switch (payload.tag) {
      case "isChecked":
        acceptedFulfilment.isChecked = payload.value;
        break;
      case "isAllChecked":
        acceptSellerFulfilments.forEach(value => {
          value.isChecked = payload.value;
        });
        isAllChecked = payload.value;
        break;
      case "quantityAccepted":
        acceptedFulfilment.quantityAccepted = payload.value;
        break;
      case "onDone":
        Object.assign(acceptedFulfilment, {
          requiredQuantity: payload.value,
          fixRequiredQuantity: payload.value
        });
        break;
      case "quantityAssigned":
        Object.assign(acceptedFulfilment, {
          quantityAssigned: payload.value,
          requiredQuantity:
            acceptedFulfilment.quantity -
            acceptedFulfilment.quantityAccepted -
            payload.value -
            acceptedFulfilment.totalQuantityAssigned
        });
        break;
      case "onAssign": {
        const newRequired =
          acceptedFulfilment.quantity -
          acceptedFulfilment.quantityAccepted -
          acceptedFulfilment.totalQuantityAssigned -
          acceptedFulfilment.quantityAssigned;
        const totalAssigned =
          acceptedFulfilment.totalQuantityAssigned +
          acceptedFulfilment.quantityAssigned;
        Object.assign(acceptedFulfilment, {
          totalQuantityAssigned: totalAssigned,
          requiredQuantity: newRequired,
          quantityAssigned: 0,
          fixRequiredQuantity: newRequired,
          isChecked: false
        });
        isAllChecked = false;
        break;
      }
      case "onReject":
        Object.assign(acceptedFulfilment, {
          requiredQuantity: 0,
          fixRequiredQuantity: 0
        });
        break;
    }
    return {
      ...state,
      acceptSellerFulfilments: [...acceptSellerFulfilments],
      isAllChecked
    };
  },

  [PUSH_TO_FINAL_REQUEST_FOR_PROCESS_PICKUP]: (state, payload) => {
    const newFinalRequest = state.finalRequest;
    const acceptSellerFulfilments = state.acceptSellerFulfilments;
    const inventoryrequest = state.inventoryrequest;
    const assignedSeller = state.assignedSeller;

    switch (payload.tag) {
      case "pushOnDone": {
        const acceptedSellerFulfilmentsArray = [];
        acceptSellerFulfilments.forEach(value => {
          if (value.quantityAccepted > 0) {
            acceptedSellerFulfilmentsArray.push({
              id: value.id,
              orderId: value.orderId,
              userName: value.BuyerFulfilment.User.name,
              buyerFulfilmentId: value.buyerFulfilmentId,
              inventoryAdditionRequestId: value.inventoryAdditionRequestId,
              quantity: value.quantityAccepted
            });
          }
        });

        if (acceptedSellerFulfilmentsArray.length > 0) {
          newFinalRequest.push({
            type: "old",
            sellerId: inventoryrequest.sellerId,
            productId: inventoryrequest.productId,
            seller_name: inventoryrequest.Seller.name,
            sellerPrice: inventoryrequest.sellerPrice,
            sellerFulfilmentArray: acceptedSellerFulfilmentsArray
          });
        }
        break;
      }
      case "pushOnAssign": {
        const newSellerFulfulmentArray = [];
        acceptSellerFulfilments.forEach(value => {
          if (
            value.isChecked === true &&
            value.quantityAssigned > 0 &&
            assignedSeller.name.length > 0
          ) {
            newSellerFulfulmentArray.push({
              id: value.id,
              quantity: value.quantityAssigned,
              userName: value.BuyerFulfilment.User.name,
              orderId: value.orderId,
              inventoryAdditionRequestId: value.inventoryAdditionRequestId,
              buyerFulfilmentId: value.buyerFulfilmentId
            });
          } else {
            console.log("1. Make selection\n2. Select Seller\n3. Select SFs");
          }
        });

        if (newSellerFulfulmentArray.length > 0) {
          newFinalRequest.push({
            type: "new",
            sellerId: assignedSeller.id,
            seller_name: assignedSeller.name,
            sellerPrice: inventoryrequest.sellerPrice,
            productId: inventoryrequest.productId,
            sellerFulfilmentArray: newSellerFulfulmentArray
          });
        }
        break;
      }
      case "pushOnReject": {
        const data = payload.data;
        newFinalRequest.push({
          type: "reject",
          sellerPrice: inventoryrequest.sellerPrice,
          sellerId: inventoryrequest.sellerId,
          seller_name: inventoryrequest.Seller.name,
          productId: inventoryrequest.productId,
          sellerFulfilmentArray: [
            {
              id: data.id,
              quantity: data.requiredQuantity,
              orderId: data.orderId,
              buyerFulfilmentId: data.buyerFulfilmentId,
              inventoryAdditionRequestId: data.inventoryAdditionRequestId,
              userName: data.BuyerFulfilment.User.name
            }
          ]
        });
        break;
      }
    }

    return {
      ...state,
      finalRequest: [...newFinalRequest]
    };
  }
});

export default processPickupReducer;
