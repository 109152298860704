import {
  ASSIGN_SHIPMENTS_TO_THIRD_PARTY_FAILURE,
  ASSIGN_SHIPMENTS_TO_THIRD_PARTY_REQUEST,
  ASSIGN_SHIPMENTS_TO_THIRD_PARTY_SUCCESS,
  CREATE_PICKUP_REQUEST_FAILURE,
  CREATE_PICKUP_REQUEST_REQUEST,
  CREATE_PICKUP_REQUEST_SUCCESS,
  FETCH_SHIPMENTS_FAILURE,
  FETCH_SHIPMENTS_REQUEST,
  FETCH_SHIPMENTS_SUCCESS,
  FETCH_THIRD_PARTY_SHIPMENTS_FAILURE,
  FETCH_THIRD_PARTY_SHIPMENTS_REQUEST,
  FETCH_THIRD_PARTY_SHIPMENTS_SUCCESS,
  FORCE_UPDATE_SHIPMENT_FAILURE,
  FORCE_UPDATE_SHIPMENT_REQUEST,
  FORCE_UPDATE_SHIPMENT_SUCCESS,
  RESET_SHIPMENTS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    shipments: []
  },
  dataFetched: false,
  isFetching: false,
  error: false,

  isPosting: false,
  dataPosted: false
};

export const shipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_SHIPMENTS_DATA:
      return initialState;

    case FETCH_SHIPMENTS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_SHIPMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case FETCH_THIRD_PARTY_SHIPMENTS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_THIRD_PARTY_SHIPMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_THIRD_PARTY_SHIPMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case ASSIGN_SHIPMENTS_TO_THIRD_PARTY_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case ASSIGN_SHIPMENTS_TO_THIRD_PARTY_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ASSIGN_SHIPMENTS_TO_THIRD_PARTY_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case CREATE_PICKUP_REQUEST_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };

    case CREATE_PICKUP_REQUEST_SUCCESS:
      return {
        ...state,
        dataPosted: true,
        isPosting: false
      };

    case CREATE_PICKUP_REQUEST_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case FORCE_UPDATE_SHIPMENT_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };

    case FORCE_UPDATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        dataPosted: true,
        isPosting: false
      };

    case FORCE_UPDATE_SHIPMENT_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default shipmentsReducer;
