import {
  CREATE_BATCH_FAILURE,
  CREATE_BATCH_REQUEST,
  CREATE_BATCH_SUCCESS,
  FETCH_BATCH_FAILURE,
  FETCH_BATCH_REQUEST,
  FETCH_BATCH_SUCCESS,
  RESET_BATCH_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  dataPosted: false,
  isPosting: false,
  dataFetched: false,
  isFetching: false,
  error: false
};

export const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BATCH_DATA:
      return initialState;

    case CREATE_BATCH_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case CREATE_BATCH_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true,
        data: action.payload
      };
    case CREATE_BATCH_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case FETCH_BATCH_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default batchReducer;
