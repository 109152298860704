import {
  FETCH_DELIVERY_PERSON_DISTANCE_FAILURE,
  FETCH_DELIVERY_PERSON_DISTANCE_REQUEST,
  FETCH_DELIVERY_PERSON_DISTANCE_SUCCESS,
  RESET_DELIVERY_PERSON_DISTANCE_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    distance: 0
  },
  dataFetched: false,
  isFetching: false,
  error: false
};

export default function deliveryPersonDistanceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case RESET_DELIVERY_PERSON_DISTANCE_DATA:
      return initialState;

    case FETCH_DELIVERY_PERSON_DISTANCE_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_DELIVERY_PERSON_DISTANCE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_DELIVERY_PERSON_DISTANCE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
}
