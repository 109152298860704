import {
  CREATE_RELEASE_FAILURE,
  CREATE_RELEASE_REQUEST,
  CREATE_RELEASE_SUCCESS,
  FETCH_RELEASES_REQUEST,
  FETCH_RELEASES_SUCCESS,
  UPDATE_RELEASE_FAILURE,
  UPDATE_RELEASE_REQUEST,
  UPDATE_RELEASE_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  releases: [],
  isFetching: true,
  release: {},
  error: false,
  isAccountFetching: true,
  isCreatingOrUpdating: false
};

export const releaseReducer = createReducer(initialState, {
  [FETCH_RELEASES_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      error: false
    };
  },
  [FETCH_RELEASES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [CREATE_RELEASE_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [CREATE_RELEASE_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_RELEASE_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [UPDATE_RELEASE_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [UPDATE_RELEASE_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_RELEASE_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  }
});

export default releaseReducer;
