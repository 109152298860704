import React, { useEffect } from "react";
import { HorizontalSwitch } from "react-horizontal-router-dom";

import CallCenter from "../components/CallCenter";
import { getBasePath } from "../utils";
import NavBarMain from "./NavBarMain";

const HorizontalRouterRoot = props => {
  const { roleRoutes, openLink, location } = props;
  useEffect(() => {
    setTimeout(() => {
      const container = document.getElementsByClassName(
        "main-horizontal-wrapper"
      )[0];
      container.scrollLeft = container.scrollWidth - container.offsetWidth;
    }, 100);
  }, [location]);

  return (
    <>
      <NavBarMain openLink={openLink} location={location} />
      <div className="app-content">
        <div
          className="main-horizontal-wrapper"
          style={{ scrollBehavior: "smooth" }}>
          <div className="horizontal-scroll-wrapper">
            <HorizontalSwitch root={getBasePath(location)}>
              {roleRoutes}
            </HorizontalSwitch>
          </div>
        </div>
        <CallCenter openLink={openLink} location={location} />
      </div>
    </>
  );
};

export default HorizontalRouterRoot;
