import _find from "lodash/find";

import {
  ASSIGN_RIDER_TO_TOUR,
  FETCH_BATCH_DETAILS_FAILURE,
  FETCH_BATCH_DETAILS_REQUEST,
  FETCH_BATCH_DETAILS_SUCCESS
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  dataPosted: false,
  isPosting: false,
  dataFetched: false,
  isFetching: false,
  planId: null,
  error: false
};

export const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BATCH_DETAILS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_BATCH_DETAILS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_BATCH_DETAILS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case ASSIGN_RIDER_TO_TOUR: {
      const myData = (state.data(
        _find(myData.result.tours, { id: action.payload.tourId })
      ).rider = action.payload.rider);
      return {
        ...state,
        data: myData
      };
    }
    default:
      return state;
  }
};

export default batchReducer;
