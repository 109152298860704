import {
  FETCH_DELIVERY_VENDOR_FAILURE,
  FETCH_DELIVERY_VENDOR_REQUEST,
  FETCH_DELIVERY_VENDOR_SUCCESS,
  RESET_DELIVERY_VENDOR_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  dataFetched: false,
  isFetching: false,
  error: false,

  isPosting: false,
  dataPosted: false
};

export const shipmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DELIVERY_VENDOR_DATA:
      return initialState;

    case FETCH_DELIVERY_VENDOR_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_DELIVERY_VENDOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_DELIVERY_VENDOR_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default shipmentsReducer;
