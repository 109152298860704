import {
  DELETE_LAYOUT_REQUEST,
  DELETE_LAYOUT_SUCCESS,
  FETCH_LAYOUT_HISTORY_REQUEST,
  FETCH_LAYOUT_HISTORY_SUCCESS,
  FETCH_LAYOUT_REQUEST,
  FETCH_LAYOUT_SUCCESS,
  FETCH_LAYOUTS_REQUEST,
  FETCH_LAYOUTS_SUCCESS,
  MARK_LAYOUT_DEFAULT_REQUEST,
  MARK_LAYOUT_DEFAULT_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  layouts: [],
  count: 0,
  isFetchingLayouts: false,
  layout: [],
  actionPage: 0,
  isFetching: false,
  isDeleting: false,
  layoutRevisionHistory: [],
  isFetchingRevisionHistory: false
};

export const layoutReducer = createReducer(initialState, {
  [FETCH_LAYOUTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingLayouts: true
    };
  },
  [FETCH_LAYOUTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingLayouts: false
    };
  },
  [FETCH_LAYOUT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_LAYOUT_SUCCESS]: (state, payload) => {
    const { page } = payload;
    const layout = state.layout
      .filter(l => l.page != page)
      .map(l => {
        const defaultLayout = l.layout._id == payload.layout._id;
        return {
          ...l,
          layout: {
            ...l.layout,
            default: defaultLayout
          }
        };
      });
    return {
      ...state,
      layout: [...layout, payload],
      isFetching: false
    };
  },
  [FETCH_LAYOUT_HISTORY_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingRevisionHistory: true
    };
  },
  [FETCH_LAYOUT_HISTORY_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingRevisionHistory: false
    };
  },
  [MARK_LAYOUT_DEFAULT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [MARK_LAYOUT_DEFAULT_SUCCESS]: (state, payload) => {
    const { layoutId } = payload;
    const layout = state.layout.map(l => {
      const defaultLayout = l.layout._id == layoutId;
      return {
        ...l,
        layout: {
          ...l.layout,
          default: defaultLayout
        }
      };
    });
    return {
      ...state,
      layout: [...layout],
      isFetching: false
    };
  },
  [DELETE_LAYOUT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isDeleting: true
    };
  },
  [DELETE_LAYOUT_SUCCESS]: (state, payload) => {
    const { page } = payload;
    const layout = state.layout.filter(l => l.page != page);
    return {
      ...state,
      layout: [...layout],
      isFetching: false,
      isDeleting: false
    };
  }
});

export default layoutReducer;
