import {
  CREATE_BATCH_PLANS_FAILURE,
  CREATE_BATCH_PLANS_REQUEST,
  CREATE_BATCH_PLANS_SUCCESS,
  FETCH_BATCH_PLANS_FAILURE,
  FETCH_BATCH_PLANS_REQUEST,
  FETCH_BATCH_PLANS_SUCCESS,
  MODIFY_BATCH_PLANS_FAILURE,
  MODIFY_BATCH_PLANS_REQUEST,
  MODIFY_BATCH_PLANS_SUCCESS,
  RESET_BATCH_PLANS_DATA,
  SAVE_BATCH_PLANS_FAILURE,
  SAVE_BATCH_PLANS_REQUEST,
  SAVE_BATCH_PLANS_SUCCESS
} from "../../constants/actionTypes";

const initialState = {
  data: null,
  dataFetched: false,
  isFetching: false,
  error: false,

  isModifying: false,
  dataModified: false,

  isFinalPosted: false
};

export const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_BATCH_PLANS_DATA:
      return initialState;

    case FETCH_BATCH_PLANS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_BATCH_PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_BATCH_PLANS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case CREATE_BATCH_PLANS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case CREATE_BATCH_PLANS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case CREATE_BATCH_PLANS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case MODIFY_BATCH_PLANS_REQUEST:
      return {
        ...state,
        dataModified: false,
        isModifying: true,
        error: false
      };
    case MODIFY_BATCH_PLANS_SUCCESS:
      return {
        ...state,
        isModifying: false,
        dataModified: true,
        data: action.payload
      };
    case MODIFY_BATCH_PLANS_FAILURE:
      return {
        ...state,
        isModifying: false,
        error: action.payload
      };

    case SAVE_BATCH_PLANS_REQUEST:
      return {
        ...state,
        dataModified: false,
        isModifying: true,
        isFinalPosted: false,
        error: false
      };
    case SAVE_BATCH_PLANS_SUCCESS:
      return {
        ...state,
        isModifying: false,
        dataModified: true,
        isFinalPosted: true,
        data: action.payload
      };
    case SAVE_BATCH_PLANS_FAILURE:
      return {
        ...state,
        isModifying: false,
        isFinalPosted: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default batchReducer;
