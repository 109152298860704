import {
  FETCH_WAREHOUSES_FAILURE,
  FETCH_WAREHOUSES_REQUEST,
  FETCH_WAREHOUSES_SUCCESS,
  RESET_WAREHOUSES_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    page: 1,
    count: 0,
    warehouses: []
  },

  dataFetched: false,
  isFetching: false,
  error: false,

  dataPosted: false,
  isPosting: false
};

export const warehousesReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_WAREHOUSES_DATA:
      return initialState;

    case FETCH_WAREHOUSES_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_WAREHOUSES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_WAREHOUSES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default warehousesReducer;
