import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: "/login",
  authenticatedSelector: state => state.auth.isAuthenticated,
  wrapperDisplayName: "UserIsAuthenticated",
  allowRedirectBack: true
});

export const userHasValidSession = connectedRouterRedirect({
  redirectPath: "/",
  authenticatedSelector: state =>
    (state.auth.session && state.auth.session.dataFilled) ||
    state.auth.isAuthenticated,
  wrapperDisplayName: "userHasValidSession",
  allowRedirectBack: false
});

const locationHelper = locationHelperBuilder({});

export const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    if (locationHelper.getRedirectQueryParam(ownProps)) {
      return locationHelper.getRedirectQueryParam(ownProps);
    }
    if (state.auth.user && state.auth.user.roles) {
      const roles = state.auth.user.roles;
      if (roles.indexOf("admin") > -1) return "/admin-dashboard";
      if (roles.indexOf("account.owner") > -1) return "/account-dashboard";
      if (roles.indexOf("account.admin") > -1) return "/account-dashboard";
      if (roles.indexOf("seller") > -1) return "/dashboard";
      if (roles.indexOf("customerCare") > -1) return "/support-dashboard";
      if (roles.indexOf("themeManager") > -1) return "/theme-manager-dashboard";
      if (roles.indexOf("adminSupport") > -1) return "/admin-support-dashboard";
      if (roles.indexOf("productEditor") > -1)
        return "/product-editor-dashboard";
      if (roles.indexOf("buyer") > -1) return "/";
      if (roles.indexOf("anonymous") > -1) return "/profile";
    } else if (
      state.auth &&
      state.auth.session &&
      state.auth.session.dataFilled
    ) {
      return "/";
    }
  },
  allowRedirectBack: false,
  authenticatedSelector: state => !state.auth.isAuthenticated,
  wrapperDisplayName: "UserIsNotAuthenticated"
});

export const userIsGuest = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    if (locationHelper.getRedirectQueryParam(ownProps)) {
      return locationHelper.getRedirectQueryParam(ownProps);
    }
    if (state.auth.user && state.auth.user.roles) {
      const roles = state.auth.user.roles;
      if (roles.indexOf("admin") > -1) return "/admin-dashboard";
      if (roles.indexOf("account.owner") > -1) return "/account-dashboard";
      if (roles.indexOf("account.admin") > -1) return "/account-dashboard";
      if (roles.indexOf("seller") > -1) return "/dashboard";
      if (roles.indexOf("customerCare") > -1) return "/support-dashboard";
      if (roles.indexOf("themeManager") > -1) return "/theme-manager-dashboard";
      if (roles.indexOf("adminSupport") > -1) return "/admin-support-dashboard";
      if (roles.indexOf("productEditor") > -1)
        return "/product-editor-dashboard";
      if (roles.indexOf("buyer") > -1) return "/";
      if (roles.indexOf("anonymous") > -1) return "/profile";
    } else if (
      state.auth &&
      state.auth.session &&
      state.auth.session.dataFilled
    ) {
      return "/";
    }
  },
  allowRedirectBack: false,
  authenticatedSelector: state =>
    !state.auth.isAuthenticated &&
    !(state.auth.session && state.auth.session.dataFilled),
  wrapperDisplayName: "UserIsNotAuthenticated"
});

export const permanentRedirect = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    if (locationHelper.getRedirectQueryParam(ownProps)) {
      return locationHelper.getRedirectQueryParam(ownProps);
    }
    if (state.auth.user && state.auth.user.roles) {
      const roles = state.auth.user.roles;
      if (roles.indexOf("admin") > -1) return "/admin-dashboard";
      if (roles.indexOf("account.owner") > -1) return "/account-dashboard";
      if (roles.indexOf("account.admin") > -1) return "/account-dashboard";
      if (roles.indexOf("seller") > -1) return "/dashboard";
      if (roles.indexOf("customerCare") > -1) return "/support-dashboard";
      if (roles.indexOf("themeManager") > -1) return "/theme-manager-dashboard";
      if (roles.indexOf("adminSupport") > -1) return "/admin-support-dashboard";
      if (roles.indexOf("productEditor") > -1)
        return "/product-editor-dashboard";
      if (roles.indexOf("buyer") > -1) return "/";
      if (roles.indexOf("anonymous") > -1) return "/profile";
    } else {
      return "/login";
    }
  },
  allowRedirectBack: false,
  authenticatedSelector: () => false,
  wrapperDisplayName: "UserIsNotAuthenticated"
});
