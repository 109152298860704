import {
  CREATE_APP_BUILD_FAILURE,
  CREATE_APP_BUILD_REQUEST,
  CREATE_APP_BUILD_SUCCESS,
  CREATE_APP_VERSION_FAILURE,
  CREATE_APP_VERSION_REQUEST,
  CREATE_APP_VERSION_SUCCESS,
  FETCH_APP_VERSIONS,
  FETCH_APP_VERSIONS_SUCCESS,
  FETCH_GITHUB_DATA_REQUEST,
  FETCH_GITHUB_DATA_SUCCESS,
  UPDATE_APP_VERSION_FAILURE,
  UPDATE_APP_VERSION_REQUEST,
  UPDATE_APP_VERSION_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  appVersions: [],
  appBranches: [],
  isFetching: true,
  isGithubFetching: true,
  appVersion: {},
  error: false,
  isCreatingOrUpdating: false,
  tags: [],
  isBuildActive: true,
  branches: {}
};

export const appVersionReducer = createReducer(initialState, {
  [FETCH_APP_VERSIONS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      error: false
    };
  },
  [FETCH_APP_VERSIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [CREATE_APP_VERSION_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [CREATE_APP_VERSION_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_APP_VERSION_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [UPDATE_APP_VERSION_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [UPDATE_APP_VERSION_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_APP_VERSION_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [CREATE_APP_BUILD_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [CREATE_APP_BUILD_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      isBuilding: true
    };
  },
  [CREATE_APP_BUILD_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [FETCH_GITHUB_DATA_REQUEST]: state => {
    return {
      ...state,
      isGithubFetching: true
    };
  },
  [FETCH_GITHUB_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      isGithubFetching: false,
      ...payload
    };
  }
});

export default appVersionReducer;
