import {
  FETCH_CREATE_USER_FAILURE,
  FETCH_CREATE_USER_REQUEST,
  FETCH_CREATE_USER_SUCCESS,
  FETCH_USERS_DATA_REQUEST,
  FETCH_USERS_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  users: {},
  suggestions: [],
  usersCount: 0,
  user_locations: [],
  isFetching: true,
  type: "retailer",
  userSearchResult: [],
  searchActive: false,
  showSearch: false,
  searchType: "name",
  userType: "all",
  userRole: "all",
  user: {},
  createUserFetching: false,
  createUserSuccess: true,
  createUserError: "",
  searchParams: {}
};

export const usersReducer = createReducer(initialState, {
  [FETCH_USERS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_USERS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_CREATE_USER_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      createUserFetching: true,
      createUserSuccess: false,
      createUserError: ""
    };
  },
  [FETCH_CREATE_USER_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      createUserFetching: false,
      createUserSuccess: true
    };
  },
  [FETCH_CREATE_USER_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      createUserFetching: false,
      createUserSuccess: false,
      createUserError: payload
    };
  }
});

export default usersReducer;
