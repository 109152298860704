import {
  ADD_KEYWORD_REQUEST,
  ADD_KEYWORD_SUCCESS,
  FETCH_AGGREGATIONS_REQUEST,
  FETCH_AGGREGATIONS_SUCCESS,
  FETCH_KEYWORD_GROUPS_REQUEST,
  FETCH_KEYWORD_GROUPS_SUCCESS,
  FETCH_KEYWORD_PRODUCT_DATA_REQUEST,
  FETCH_KEYWORD_PRODUCT_DATA_SUCCESS,
  FETCH_KEYWORDS_REQUEST,
  FETCH_KEYWORDS_SUCCESS,
  REMOVE_KEYWORD_SUCCESS,
  UPDATE_KEYWORD_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  actionPage: 0,
  fetchingGroups: false,
  groups: [],
  keywords: [],
  isFetchingAggregations: false,
  aggregations: [],
  categories: [],
  subCategories: [],
  isFetchingKeywordProductData: false,
  keywordProductData: {}
};

export const keywordReducer = createReducer(initialState, {
  [FETCH_KEYWORDS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_KEYWORDS_SUCCESS]: (state, payload) => {
    let keywords = state.keywords;
    keywords = keywords.filter(keyword => keyword.page != payload.page);
    return {
      ...state,
      keywords: [...keywords, payload],
      isFetching: false
    };
  },
  [ADD_KEYWORD_REQUEST]: (state, payload) => {
    const { page } = payload;
    const keywords = state.keywords.map(keyword => {
      if (keyword.page === page) {
        return {
          ...keyword,
          uploading: true
        };
      }
      return keyword;
    });
    return {
      ...state,
      keywords: [...keywords]
    };
  },
  [ADD_KEYWORD_SUCCESS]: (state, payload) => {
    const { page, data } = payload;
    const keywords = state.keywords.map(keyword => {
      if (keyword.page === page) {
        return {
          ...keyword,
          data: [...keyword.data, data],
          uploading: false
        };
      }
      return keyword;
    });
    return {
      ...state,
      keywords: [...keywords]
    };
  },
  [REMOVE_KEYWORD_SUCCESS]: (state, payload) => {
    const { page, keyword: keywordId } = payload;
    const keywords = state.keywords.map(keyword => {
      if (keyword.page === page) {
        return {
          ...keyword,
          data: keyword.data.filter(d => d._id != keywordId)
        };
      }
      return keyword;
    });
    return {
      ...state,
      keywords: [...keywords]
    };
  },
  [UPDATE_KEYWORD_SUCCESS]: (state, payload) => {
    const { page, data } = payload;
    const keywords = state.keywords.map(keyword => {
      if (keyword.page === page) {
        return {
          ...keyword,
          data: keyword.data.map(d => {
            if (d._id === data._id) {
              return data;
            }
            return d;
          }),
          uploading: false
        };
      }
      return keyword;
    });
    return {
      ...state,
      keywords: [...keywords]
    };
  },
  [FETCH_KEYWORD_GROUPS_REQUEST]: state => {
    return {
      ...state,
      fetchingGroups: true
    };
  },
  [FETCH_KEYWORD_GROUPS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      fetchingGroups: false
    };
  },
  [FETCH_KEYWORD_PRODUCT_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetchingKeywordProductData: true,
      keywordProductData: {}
    };
  },
  [FETCH_KEYWORD_PRODUCT_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingKeywordProductData: false
    };
  },
  [FETCH_AGGREGATIONS_REQUEST]: state => {
    return {
      ...state,
      isFetchingAggregations: true
    };
  },
  [FETCH_AGGREGATIONS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingAggregations: false
    };
  }
});

export default keywordReducer;
