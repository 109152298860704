import {
  ALLOT_PICKUPS_TO_WAREHOUSE_FAILURE,
  ALLOT_PICKUPS_TO_WAREHOUSE_REQUEST,
  ALLOT_PICKUPS_TO_WAREHOUSE_SUCCESS,
  ASSIGN_PICKUPS_FAILURE,
  ASSIGN_PICKUPS_REQUEST,
  ASSIGN_PICKUPS_SUCCESS,
  FETCH_PICKUPS_FAILURE,
  FETCH_PICKUPS_REQUEST,
  FETCH_PICKUPS_SUCCESS,
  RESET_PICKUPS_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: {
    count: 0,
    pickups: []
  },

  dataFetched: false,
  isFetching: false,
  error: false,

  dataPosted: false,
  isPosting: false
};

export const pickupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PICKUPS_DATA:
      return initialState;
    case FETCH_PICKUPS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_PICKUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_PICKUPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case ASSIGN_PICKUPS_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case ASSIGN_PICKUPS_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ASSIGN_PICKUPS_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case ALLOT_PICKUPS_TO_WAREHOUSE_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case ALLOT_PICKUPS_TO_WAREHOUSE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ALLOT_PICKUPS_TO_WAREHOUSE_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default pickupsReducer;
