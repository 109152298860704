import {
  ADD_PRODUCT_TO_CART_DATA,
  ADD_PRODUCT_TO_CART_REQUEST,
  FETCH_CART_DATA_REQUEST,
  FETCH_CART_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  isCartUpdating: false,
  cart: {}
};

export const cartReducer = createReducer(initialState, {
  [FETCH_CART_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_CART_DATA_REQUEST]: () => {
    return { ...initialState };
  },
  [ADD_PRODUCT_TO_CART_DATA]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCartUpdating: false
    };
  },
  [ADD_PRODUCT_TO_CART_REQUEST]: state => {
    return {
      ...state,
      isCartUpdating: true
    };
  },
  [ADD_PRODUCT_TO_CART_DATA]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCartUpdating: false
    };
  }
});

export default cartReducer;
