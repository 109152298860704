import {
  FETCH_PICKUPS_DATA_REQUEST,
  FETCH_PICKUPS_DATA_SUCCESS,
  FILTER_PICKUPS_TYPE
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  pickups: [],
  pickupsCount: 0,
  nav: "all",
  isFetching: false,
  type: "seller"
};

export const pickupsReducer = createReducer(initialState, {
  [FETCH_PICKUPS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_PICKUPS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FILTER_PICKUPS_TYPE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  }
});

export default pickupsReducer;
