import {
  FETCH_CANCEL_PRODUCT_SUCCESS,
  FETCH_CONFIRM_ORDER,
  FETCH_CONFIRM_ORDER_DATA_SUCCESS,
  FETCH_ORDER_DATA_REQUEST,
  FETCH_ORDER_DATA_SUCCESS,
  REQUEST_CANCEL_PRODUCT,
  RESET_ORDER_DATA,
  UPDATE_INVENTORY_DATA,
  UPDATE_INVENTORY_REQUEST
} from "../constants/actionTypes";
import { createReducer } from "../utils";

export const initialOrderState = {
  isFetching: true,
  isCreating: false,
  updatingInventory: false,
  isCancellingProduct: false,
  order: {}
};

const initialState = {
  order: {}
};

export const orderReducer = createReducer(initialState, {
  [FETCH_ORDER_DATA_REQUEST]: (state, payload) => {
    const page = payload.page || 0;
    const order = state.order[page] || initialOrderState;

    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          isFetching: true
        }
      }
    };
  },
  [FETCH_ORDER_DATA_SUCCESS]: (state, payload) => {
    const { page, ...finalPayload } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          ...finalPayload,
          isFetching: false
        }
      }
    };
  },
  [RESET_ORDER_DATA]: (state, payload) => {
    const page = payload.page || 0;
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...initialOrderState
        }
      }
    };
  },
  [FETCH_CONFIRM_ORDER]: (state, payload) => {
    const { page, ...finalPayload } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          ...finalPayload,
          isCreating: true
        }
      }
    };
  },
  [FETCH_CONFIRM_ORDER_DATA_SUCCESS]: (state, payload) => {
    const { page, ...finalPayload } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          ...finalPayload,
          isCreating: false,
          isFetching: false
        }
      }
    };
  },
  [REQUEST_CANCEL_PRODUCT]: (state, payload) => {
    const { page, ...finalPayload } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          ...finalPayload,
          isCancellingProduct: true
        }
      }
    };
  },
  [FETCH_CANCEL_PRODUCT_SUCCESS]: (state, payload) => {
    const { page, ...finalPayload } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          ...finalPayload,
          isCancellingProduct: false
        }
      }
    };
  },
  [UPDATE_INVENTORY_REQUEST]: (state, payload) => {
    const { page } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          updatingInventory: true
        }
      }
    };
  },
  [UPDATE_INVENTORY_DATA]: (state, payload) => {
    const { page } = payload;
    const order = state.order[page];
    return {
      ...state,
      order: {
        ...state.order,
        [page]: {
          ...order,
          updatingInventory: false
        }
      }
    };
  }
});

export default orderReducer;
