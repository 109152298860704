import {
  CREATE_OR_UPDATE_CUSTOMER_GROUP_REQUEST,
  FETCH_CUSTOMER_GROUP_REQUEST,
  FETCH_CUSTOMER_GROUP_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  data: [],
  isFetching: true,
  isCreatingOrUpdating: true
};

export const customerGroupReducer = createReducer(initialState, {
  [FETCH_CUSTOMER_GROUP_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_OR_UPDATE_CUSTOMER_GROUP_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [FETCH_CUSTOMER_GROUP_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isCreatingOrUpdating: false
    };
  }
});

export default customerGroupReducer;
