import React, { Children, cloneElement, useEffect, useState } from "react";

export const Menu = props => {
  const { children, currentPathname } = props;
  const [active, setActive] = useState(null);
  useEffect(() => {
    Children.map(children, (child, index) => {
      const hasChildren = !!Children.count(child.children);
      hasChildren &&
        Children.map(child.children, innerChild => {
          // Getting active menuItem from route. Using map as there is no some in Children
          if (
            innerChild &&
            currentPathname &&
            currentPathname.indexOf(innerChild.route) > -1
          ) {
            setActive(index);
          }
        });
      //Getting active menuItem from route   //Using map as there is no some in Children
      if (
        child &&
        currentPathname &&
        currentPathname.indexOf(child.route) > -1
      ) {
        setActive(index);
      }
    });
  }, []);

  return (
    <nav className="scroll nav-stacked nav-stacked-rounded nav-color">
      <ul className="nav">
        {Children.map(children, (child, index) =>
          !child ? (
            <div />
          ) : child.type.name === "MenuDivider" ? (
            child
          ) : (
            cloneElement(child, {
              onClick: () => setActive(active === index ? null : index), //If the current tab is already selected, then unselect it (to collapse subMenu), else select the tab
              active: active === index,
              currentPathname: currentPathname
            })
          )
        )}
      </ul>
    </nav>
  );
};
