import {
  CREATE_THEME_FAILURE,
  CREATE_THEME_REQUEST,
  CREATE_THEME_SUCCESS,
  FETCH_THEMES_REQUEST,
  FETCH_THEMES_SUCCESS,
  UPDATE_THEME_FAILURE,
  UPDATE_THEME_REQUEST,
  UPDATE_THEME_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  themes: [],
  isFetching: true,
  theme: {},
  error: false,
  isAccountFetching: true,
  isCreatingOrUpdating: false
};

export const themeReducer = createReducer(initialState, {
  [FETCH_THEMES_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      error: false
    };
  },
  [FETCH_THEMES_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [CREATE_THEME_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [CREATE_THEME_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_THEME_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [UPDATE_THEME_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: false
    };
  },
  [UPDATE_THEME_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_THEME_FAILURE]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  }
});

export default themeReducer;
