import {
  CREATE_DELIVERY_PERSON_FAILURE,
  CREATE_DELIVERY_PERSON_REQUEST,
  CREATE_DELIVERY_PERSON_SUCCESS,
  DELETE_DELIVERY_PERSON_FAILURE,
  DELETE_DELIVERY_PERSON_REQUEST,
  DELETE_DELIVERY_PERSON_SUCCESS,
  EDIT_DELIVERY_PERSON_FAILURE,
  EDIT_DELIVERY_PERSON_REQUEST,
  EDIT_DELIVERY_PERSON_SUCCESS,
  FETCH_DELIVERY_PERSON_FAILURE,
  FETCH_DELIVERY_PERSON_REQUEST,
  FETCH_DELIVERY_PERSON_SUCCESS,
  RESET_DELIVERY_PERSON_DATA
} from "../../constants/actionTypes";

const initialState = {
  data: null,

  dataFetched: false,
  isFetching: false,
  error: false,

  dataPosted: false,
  isPosting: false
};

export const deliveryStaffReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_DELIVERY_PERSON_DATA:
      return initialState;

    case FETCH_DELIVERY_PERSON_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_DELIVERY_PERSON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_DELIVERY_PERSON_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case CREATE_DELIVERY_PERSON_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case CREATE_DELIVERY_PERSON_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case CREATE_DELIVERY_PERSON_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case DELETE_DELIVERY_PERSON_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case DELETE_DELIVERY_PERSON_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case DELETE_DELIVERY_PERSON_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case EDIT_DELIVERY_PERSON_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case EDIT_DELIVERY_PERSON_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case EDIT_DELIVERY_PERSON_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default deliveryStaffReducer;
