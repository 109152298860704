import {
  FETCH_UPLOAD_PRODUCTS_DATA_REQUEST,
  FETCH_UPLOAD_PRODUCTS_DATA_SUCCESS,
  FETCH_UPLOADS_DATA_REQUEST,
  FETCH_UPLOADS_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: false,
  productsFetching: false,
  uploads: [],
  products: [],
  productCount: 0,
  productPage: 0,
  page: 0,
  count: 0
};

export const uploadsReducer = createReducer(initialState, {
  [FETCH_UPLOADS_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_UPLOADS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_UPLOAD_PRODUCTS_DATA_REQUEST]: state => {
    return {
      ...state,
      productsFetching: true
    };
  },
  [FETCH_UPLOAD_PRODUCTS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      productsFetching: false
    };
  }
});

export default uploadsReducer;
