import {
  APPEND_TO_OPEN_REFS_ARRAY,
  AUTOMATE_PLAY_ACCOUNT_FAILURE,
  AUTOMATE_PLAY_ACCOUNT_REQUEST,
  AUTOMATE_PLAY_ACCOUNT_SUCCESS,
  CLEANUP_PLAY_ACCOUNT_FAILURE,
  CLEANUP_PLAY_ACCOUNT_REQUEST,
  CLEANUP_PLAY_ACCOUNT_SUCCESS,
  CREATE_PLAY_ACCOUNT_REQUEST,
  CREATE_PLAY_ACCOUNT_SUCCESS,
  CREATE_PLAY_APP_FAILURE,
  CREATE_PLAY_APP_PREVIEW_SUCCESS,
  CREATE_PLAY_APP_REQUEST,
  CREATE_PLAY_APP_SUCCESS,
  ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_FAILURE,
  ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_REQUEST,
  ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_SUCCESS,
  FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS,
  FETCH_PLAY_ACCOUNT,
  FETCH_PLAY_ACCOUNT_SUCCESS,
  FETCH_PLAY_ACCOUNTS,
  FETCH_PLAY_ACCOUNTS_SUCCESS,
  PLAYSTORE_ACCOUNT_UNLOCK_FAILURE,
  PLAYSTORE_ACCOUNT_UNLOCK_REQUEST,
  PLAYSTORE_ACCOUNT_UNLOCK_SUCCESS,
  PLAYSTORE_AUTOMATOR_OTP_FAILURE,
  PLAYSTORE_AUTOMATOR_OTP_REQUEST,
  PLAYSTORE_AUTOMATOR_OTP_SUCCESS,
  PLAYSTORE_AUTOMATOR_POST_FAILURE,
  PLAYSTORE_AUTOMATOR_POST_REQUEST,
  PLAYSTORE_AUTOMATOR_POST_SUCCESS,
  RESET_OPEN_REFS_ARRAY,
  UPDATE_PLAY_ACCOUNT_REQUEST,
  UPDATE_PLAY_ACCOUNT_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  accounts: [],
  page: 1,
  count: 0,
  isFetching: true,
  account: {},
  isAccountFetching: true,
  isCreatingOrUpdating: false,
  isUnlockingPlayAccount: false,
  isFirebaseEnsureRequesting: false,
  automatorSession: {
    active: false,
    otpRequested: false,
    otpSuccessful: false,
    otpWrong: false,
    sessionFailed: false
  },
  playAppPreview: null,
  error: null,
  isCleanupPlayAccountSuccess: false,
  openRefs: []
};

export const playAccountsReducer = createReducer(initialState, {
  [FETCH_PLAY_ACCOUNTS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_PLAY_ACCOUNTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_PLAY_ACCOUNT]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isAccountFetching: true,
      error: null
    };
  },
  [FETCH_PLAY_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      account: {
        ...state.account,
        [String(payload.account._id)]: payload.account
      },
      isAccountFetching: false
    };
  },
  [CREATE_PLAY_ACCOUNT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [CREATE_PLAY_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_PLAY_ACCOUNT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [UPDATE_PLAY_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      account: {
        ...state.account,
        [String(payload.account._id)]: payload.account
      },
      isCreatingOrUpdating: false
    };
  },
  [FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS]: (state, payload) => {
    if (!payload.data) return state;
    return {
      ...state,
      automatorSession: payload.data
    };
  },
  [PLAYSTORE_AUTOMATOR_POST_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [PLAYSTORE_AUTOMATOR_POST_SUCCESS]: (state, payload) => {
    return {
      ...state,
      data: payload,
      error: null,
      isCreatingOrUpdating: false
    };
  },
  [PLAYSTORE_AUTOMATOR_POST_FAILURE]: (state, payload) => {
    return {
      ...state,
      error: payload.error,
      isCreatingOrUpdating: false
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [CLEANUP_PLAY_ACCOUNT_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [CLEANUP_PLAY_ACCOUNT_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      isCleanupPlayAccountSuccess: true
    };
  },
  [CLEANUP_PLAY_ACCOUNT_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_REQUEST]: state => {
    return {
      ...state,
      isFirebaseEnsureRequesting: true
    };
  },
  [ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_SUCCESS]: state => {
    return {
      ...state,
      isFirebaseEnsureRequesting: false
    };
  },
  [ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_FAILURE]: state => {
    return {
      ...state,
      isFirebaseEnsureRequesting: false,
      error: "Ensure Firebase Related Services Failed"
    };
  },
  [CREATE_PLAY_APP_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true,
      error: null
    };
  },
  [CREATE_PLAY_APP_PREVIEW_SUCCESS]: (state, payload) => {
    return {
      ...state,
      playAppPreview: payload,
      isCreatingOrUpdating: false
    };
  },
  [CREATE_PLAY_APP_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: null
    };
  },
  [CREATE_PLAY_APP_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [AUTOMATE_PLAY_ACCOUNT_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [AUTOMATE_PLAY_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      account: payload,
      isCreatingOrUpdating: false
    };
  },
  [AUTOMATE_PLAY_ACCOUNT_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [PLAYSTORE_ACCOUNT_UNLOCK_REQUEST]: state => {
    return {
      ...state,
      isUnlockingPlayAccount: true
    };
  },
  [PLAYSTORE_ACCOUNT_UNLOCK_FAILURE]: (state, payload) => {
    return {
      ...state,
      isUnlockingPlayAccount: false,
      error: payload.error.message
    };
  },
  [PLAYSTORE_ACCOUNT_UNLOCK_SUCCESS]: state => {
    return {
      ...state,
      isUnlockingPlayAccount: false
    };
  },
  [APPEND_TO_OPEN_REFS_ARRAY]: (state, payload) => {
    let openRefs = state.openRefs;
    if (openRefs.includes(payload)) {
      return state;
    } else {
      openRefs = [...openRefs, payload];
      return {
        ...state,
        openRefs
      };
    }
  },
  [RESET_OPEN_REFS_ARRAY]: state => {
    return {
      ...state,
      openRefs: []
    };
  }
});

export default playAccountsReducer;
