import {
  FETCH_ONGOING_TRIPS,
  FETCH_ONGOING_TRIPS_FAILURE,
  FETCH_ONGOING_TRIPS_SUCCESS,
  FETCH_TRIPS_FAILURE,
  FETCH_TRIPS_REQUEST,
  FETCH_TRIPS_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  ongoingTrips: [],
  data: [],
  dataFetched: false,
  isFetching: false
};

export const tripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONGOING_TRIPS:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_ONGOING_TRIPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_ONGOING_TRIPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_TRIPS_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_TRIPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_TRIPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default tripsReducer;
