import {
  FETCH_SELLER_FULFILMENTS,
  FETCH_SELLER_FULFILMENTS_SUCCESS,
  UPDATE_SELLER_FULFILMENTS
} from "../../constants/sellers";
import { createReducer } from "../../utils";

const initialState = {
  isFetching: true,
  count: 0,
  fulfilments: [],
  page: 1,
  type: "new",
  isUpdating: false
};

export const fulfilmentsReducer = createReducer(initialState, {
  [FETCH_SELLER_FULFILMENTS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true,
      isUpdating: false
    };
  },
  [FETCH_SELLER_FULFILMENTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isUpdating: false
    };
  },
  [UPDATE_SELLER_FULFILMENTS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isUpdating: true
    };
  }
});

export default fulfilmentsReducer;
