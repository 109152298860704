import {
  ALLOT_ROUTE_PLAN_TO_BATCH_FAILURE,
  ALLOT_ROUTE_PLAN_TO_BATCH_REQUEST,
  ALLOT_ROUTE_PLAN_TO_BATCH_SUCCESS,
  ASSIGN_ROUTE_INSTANCE_FAILURE,
  ASSIGN_ROUTE_INSTANCE_REQUEST,
  ASSIGN_ROUTE_INSTANCE_SUCCESS,
  CREATE_ROUTE_PLAN_FAILURE,
  CREATE_ROUTE_PLAN_REQUEST,
  CREATE_ROUTE_PLAN_SUCCESS,
  FETCH_EXPECTED_SHIPMENT_PRICE_FAILURE,
  FETCH_EXPECTED_SHIPMENT_PRICE_REQUEST,
  FETCH_EXPECTED_SHIPMENT_PRICE_SUCCESS,
  FETCH_ROUTE_PLAN_FAILURE,
  FETCH_ROUTE_PLAN_FOR_BATCH_FAILURE,
  FETCH_ROUTE_PLAN_FOR_BATCH_REQUEST,
  FETCH_ROUTE_PLAN_FOR_BATCH_SUCCESS,
  FETCH_ROUTE_PLAN_REQUEST,
  FETCH_ROUTE_PLAN_SUCCESS,
  RESET_EXPECT_SHIPMENT_PRICE,
  RESET_ROUTE_PLAN_DATA,
  UPDATE_ROUTE_PLAN_FAILURE,
  UPDATE_ROUTE_PLAN_REQUEST,
  UPDATE_ROUTE_PLAN_SUCCESS
} from "../../constants/actionTypes";

const initialState = {
  data: [], //No need count as we always need the whole route plan
  dataFetched: false,
  isFetching: false,
  error: false,
  dataPosted: false,
  isPosting: false,
  expectedPricings: {}
};

export const shipmentRoutePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ROUTE_PLAN_DATA:
      return initialState;
    case FETCH_EXPECTED_SHIPMENT_PRICE_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case RESET_EXPECT_SHIPMENT_PRICE:
      return initialState;
    case FETCH_EXPECTED_SHIPMENT_PRICE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    case FETCH_EXPECTED_SHIPMENT_PRICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        expectedPricings: action.payload
      };
    case FETCH_ROUTE_PLAN_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_ROUTE_PLAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_ROUTE_PLAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    case CREATE_ROUTE_PLAN_REQUEST:
      return {
        ...state,
        isPosting: true,
        dataPosted: false,
        error: false
      };
    case CREATE_ROUTE_PLAN_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case CREATE_ROUTE_PLAN_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case UPDATE_ROUTE_PLAN_REQUEST:
      return {
        ...state,
        isPosting: true,
        dataPosted: false,
        error: false
      };
    case UPDATE_ROUTE_PLAN_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case UPDATE_ROUTE_PLAN_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    case ALLOT_ROUTE_PLAN_TO_BATCH_REQUEST:
      return {
        ...state,
        dataPosted: false,
        isPosting: true,
        error: false
      };
    case ALLOT_ROUTE_PLAN_TO_BATCH_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ALLOT_ROUTE_PLAN_TO_BATCH_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };
    case ASSIGN_ROUTE_INSTANCE_REQUEST:
      return {
        ...state,
        isPosting: true,
        dataPosted: false,
        error: false
      };
    case ASSIGN_ROUTE_INSTANCE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        dataPosted: true
      };
    case ASSIGN_ROUTE_INSTANCE_FAILURE:
      return {
        ...state,
        isPosting: false,
        error: action.payload
      };

    case FETCH_ROUTE_PLAN_FOR_BATCH_REQUEST:
      return {
        ...state,
        dataFetched: false,
        isFetching: true,
        error: false
      };
    case FETCH_ROUTE_PLAN_FOR_BATCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFetched: true,
        data: action.payload
      };
    case FETCH_ROUTE_PLAN_FOR_BATCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default shipmentRoutePlanReducer;
