import {
  FETCH_SELLER_PRODUCTS,
  FETCH_SELLER_PRODUCTS_SUCCESS,
  RESET_SELLER_PRODUCTS,
  UPDATE_SELLER_PRODUCT_REQUEST,
  UPDATE_SELLER_PRODUCT_SUCCESS
} from "../../constants/actionTypes";
import { createReducer } from "../../utils";

const initialState = {
  isFetching: true,
  isUpdating: false,
  products: [],
  query: {},
  aggregations: [],
  total: 0,
  text: "",
  filters: {},
  page: 1,
  sort: "",
  size: 48,
  noFilter: false,
  updatingProduct: [],
  bulkUpdates: {}
};

export const sellerProductsReducer = createReducer(initialState, {
  [FETCH_SELLER_PRODUCTS]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_SELLER_PRODUCTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [RESET_SELLER_PRODUCTS]: () => {
    return initialState;
  },
  [UPDATE_SELLER_PRODUCT_REQUEST]: (state, payload) => {
    const products = payload.forEach(product => product._id);
    return {
      ...state,
      updatingProducts: {
        ...state.updatingProducts,
        ...products
      },
      isFetching: true
    };
  },
  [UPDATE_SELLER_PRODUCT_SUCCESS]: (state, payload) => {
    let products = state.products;
    payload.forEach(updatedProduct => {
      products = products.map(product => {
        if (product._id == updatedProduct._id) {
          product = [...product, updatedProduct];
        }
        return product;
      });
    });
    return {
      ...state,
      products: [...products],
      updatingProducts: [],
      isFetching: false
    };
  }
});

export default sellerProductsReducer;
