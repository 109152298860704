import _find from "lodash/find";

import {
  CHECKBOX_INVENTORY_FOR_RETURN,
  FETCH_INVENTORIES_DATA_REQUEST,
  FETCH_INVENTORIES_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  isFetching: true,
  page: 1,
  type: "order",
  inventoriesData: [],
  isAllChecked: false
};

export const inventoriesReducer = createReducer(initialState, {
  [FETCH_INVENTORIES_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_INVENTORIES_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [CHECKBOX_INVENTORY_FOR_RETURN]: (state, payload) => {
    const inventoriesData = state.inventoriesData;
    let isAllChecked = state.isAllChecked;
    switch (payload.op) {
      case "add":
        _find(inventoriesData, { id: payload.id }).isChecked = true;
        break;
      case "remove":
        _find(inventoriesData, { id: payload.id }).isChecked = false;
        break;
      case "all":
        inventoriesData.forEach(value => {
          value.isChecked = true;
        });
        isAllChecked = true;
        break;
      case "none":
        inventoriesData.forEach(value => {
          value.isChecked = false;
        });
        isAllChecked = false;
        break;
    }
    return {
      ...state,
      inventoriesData: [...inventoriesData],
      isAllChecked
    };
  }
});

export default inventoriesReducer;
