import _find from "lodash/find";

import {
  FETCH_SHIPMENTS_DATA_REQUEST,
  FETCH_SHIPMENTS_DATA_SUCCESS,
  FILTER_SHIPMENTS_TYPE,
  SELECT_SHIPMENT_FOR_INVOICE
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  page: 1,
  shipments: [],
  shipmentsCount: 0,
  status: "",
  isFetching: false,
  type: "buyer",
  isAllChecked: false
};

export const shipmentsReducer = createReducer(initialState, {
  [FETCH_SHIPMENTS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false,
      isAllChecked: false
    };
  },
  [FETCH_SHIPMENTS_DATA_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FILTER_SHIPMENTS_TYPE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [SELECT_SHIPMENT_FOR_INVOICE]: (state, payload) => {
    let shipments = state.shipments;
    let isAllChecked = state.isAllChecked;
    const shipment = _find(shipments, { id: payload.id });
    switch (payload.operation) {
      case "single":
        shipment.isChecked = !shipment.isChecked;
        break;
      case "all":
        shipments = shipments.map(value => {
          value.isChecked = !isAllChecked;
          return value;
        });
        isAllChecked = !isAllChecked;
        break;
    }
    return {
      ...state,
      shipments: [...shipments],
      isAllChecked
    };
  }
});

export default shipmentsReducer;
