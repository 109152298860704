import {
  FETCH_BUYER_ORDERS_DATA_REQUEST,
  FETCH_BUYER_ORDERS_DATA_SUCCESS,
  FETCH_ORDERS_DATA_REQUEST,
  FETCH_ORDERS_DATA_SUCCESS,
  FILTER_ORDERS_TYPE,
  RESET_ORDERS_DATA
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  orders: {},
  isFetching: false,
  suggestions: []
};

export const initialOrderState = {
  page: 1,
  orders: [],
  ordersCount: 0,
  status: "new",
  type: "new",
  orderType: "sellerOrders",
  isFetching: false
};

export const ordersReducer = createReducer(initialState, {
  [FETCH_ORDERS_DATA_REQUEST]: (state, payload = {}) => {
    const page = payload.page || 0;
    const orders = state.orders[page] || initialOrderState;
    return {
      ...state,
      orders: {
        ...state.orders,
        [page]: {
          ...orders,
          isFetching: true,
          orderType: payload.orderType
        }
      }
    };
  },
  [FETCH_ORDERS_DATA_SUCCESS]: (state, payload = {}) => {
    const page = payload.page || 0;
    const orders = state.orders[page];
    return {
      ...state,
      orders: {
        ...state.orders,
        [page]: {
          ...orders,
          ...payload.response,
          isFetching: false,
          orderType: payload.orderType
        }
      }
    };
  },
  [RESET_ORDERS_DATA]: (state, payload = {}) => {
    const page = payload.page || 0;
    return {
      ...state,
      orders: {
        ...state.orders,
        [page]: {
          ...initialOrderState
        }
      }
    };
  },
  [FILTER_ORDERS_TYPE]: (state, payload) => {
    return {
      ...state,
      ...payload
    };
  },
  [FETCH_BUYER_ORDERS_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [FETCH_BUYER_ORDERS_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  }
});

export default ordersReducer;
