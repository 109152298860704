import {
  CREATE_LAYOUT_RULE_REQUEST,
  CREATE_LAYOUT_RULE_SUCCESS,
  FETCH_LAYOUT_RULE_DATA_REQUEST,
  FETCH_LAYOUT_RULE_DATA_SUCCESS,
  FETCH_LAYOUT_RULES_DATA_REQUEST,
  FETCH_LAYOUT_RULES_DATA_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  layoutRule: {},
  layoutRules: {},
  error: "",
  isFetching: false,
  isCreating: false
};

export const layoutRulesReducer = createReducer(initialState, {
  [FETCH_LAYOUT_RULES_DATA_REQUEST]: (state, payload) => {
    const { page, url } = payload;
    return {
      ...state,
      layoutRules: {
        ...state.layoutRules,
        [page]: {
          isFetching: true,
          rules: [],
          url
        }
      }
    };
  },
  [FETCH_LAYOUT_RULES_DATA_SUCCESS]: (state, payload) => {
    const { page, url, data } = payload;
    return {
      ...state,
      layoutRules: {
        ...state.layoutRules,
        [page]: {
          isFetching: false,
          rules: data,
          url
        }
      }
    };
  },
  [CREATE_LAYOUT_RULE_REQUEST]: state => {
    return {
      ...state,
      isCreating: true,
      error: ""
    };
  },
  [CREATE_LAYOUT_RULE_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreating: false
    };
  },
  [FETCH_LAYOUT_RULE_DATA_REQUEST]: state => {
    return {
      ...state,
      isFetching: true,
      layoutRule: {}
    };
  },
  [FETCH_LAYOUT_RULE_DATA_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  }
});

export default layoutRulesReducer;
