import {
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS
} from "../constants/actionTypes";

const initialState = {
  list: [],
  fetching: false,
  error: null
};

const contactsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTACTS_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: payload
      };
    }

    case FETCH_CONTACTS_SUCCESS: {
      return {
        ...state,
        fetching: false,
        list: payload.records
      };
    }

    case FETCH_CONTACTS_REQUEST: {
      return {
        ...state,
        fetching: true
      };
    }

    default: {
      return state;
    }
  }
};

export default contactsReducer;
